/* The actual timeline (the vertical ruler) */
.axotimeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.axotimeline::after {
  content: "";
  position: absolute;
  width: 2px;
  background-color: black;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -6px;
}

/* Container around content */
.timelinecontainer {
  padding: 10px 30px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timelinecontainer::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  right: -5px;
  background-color: #1c4e80;
  border: 2px solid white;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 21px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #009933;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 21px;
  border: medium solid #009933;
  border-width: 10px 10px 10px 0;
  border-color: transparent #009933 transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -14px;
}

/* The actual content */
.timelinecontent {
  padding: 10px 10px;
  background-color: #ececec;
  position: relative;
  border-radius: 5px;
  border: 1px solid white;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media all and (max-width: 1200px) {
  .timelinecontainer {
    width: 50%;
    padding-left: 15px;
    padding-right: 38px;
  }
  .timelinecontainer.right {
    width: 50%;
    padding-left: 38px;
    padding-right: 15px;
  }

  .left::before {
    right: 28px;
  }
  .right::before {
    left: 28px;
  }
}
@media all and (max-width: 900px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timelinecontainer {
    width: 100%;
    padding-left: 48px;
    padding-right: 5px;
  }

  .timelinecontainer.right {
    width: 100%;
    padding-left: 48px;
    padding-right: 5px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timelinecontainer::before {
    left: 40px;
    border: medium solid #009933;
    border-width: 10px 10px 10px 0;
    border-color: transparent #009933 transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
  /* The actual timeline (the vertical ruler) */
  .axotimeline::after {
    content: "";
    position: absolute;
    width: 2px;
    background-color: black;
    top: 0;
    bottom: 0;
    left: 32px;
    margin-left: -6px;
  }
}

//Act like on a small screen
.single-column {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timelinecontainer {
    width: 100%;
    padding-left: 48px;
    padding-right: 5px;
  }

  .timelinecontainer.right {
    width: 100%;
    padding-left: 48px;
    padding-right: 5px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timelinecontainer::before {
    left: 40px;
    border: medium solid #009933;
    border-width: 10px 10px 10px 0;
    border-color: transparent #009933 transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 17px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
  /* The actual timeline (the vertical ruler) */
  .axotimeline::after {
    content: "";
    position: absolute;
    width: 2px;
    background-color: black;
    top: 0;
    bottom: 0;
    left: 32px;
    margin-left: -6px;
  }
}
