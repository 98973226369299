@media (min-width: 0px) {
  .Topheight {
    height: 74px;
  }
}

@media (min-width: 768px) {
  .Topheight {
    height: 74px;
  }
}

@media (min-width: 992px) {
  .Topheight {
    height: 74px;
  }
  .body-margin {
    margin-left: 250px;
  }
  .body-margin-no-sidebar {
    margin-left: 60px;
  }
  .main-sidebar {
    top: 74px;
  }
  .mini-sidebar {
    top: 74px;
  }
}

@media (min-width: 1200px) {
  .Topheight {
    height: 80px;
  }
  .main-sidebar {
    top: 80px;
  }
  .mini-sidebar {
    top: 80px;
  }
}

.header-position {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 900;
}

.main-sidebar {
  width: 250px;
}

.main-sidebar.fixed {
  position: fixed;
  overflow-y: scroll;
  width: 250px;
  left: 0px;
  bottom: 30px;
  padding: 0px;
}

.mini-sidebar.fixed {
  /* position: fixed; */
  overflow: hidden;
  width: 60px;
  /* left: 0px;
  bottom: 0px;
  padding: 0px;
  background: linear-gradient(#eaecec, #eaecec) !important */
}

/* .mini-sidebar.fixed .axo-icon {
  position: fixed;
  bottom: 45%
} */

@media (min-width: 0px) {
  .collapse-arrow {
    display: none;
  }
}

@media (min-width: 992px) {
  .collapse-arrow {
    display: block;
    position: fixed;
    width: 50px;
    left: 212px;
    bottom: 45%;
    padding: 0px;
    z-index: 99;
  }
}

.timer-container {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 250px;
  height: 30px;
}

/* Prevents slick carousel from disabling sidebar controls */
.sidebar-right {
  z-index: 10;
}
