html.social body .sidebar-nav > li.active:after {
  display: none !important;
}
.Axosocial-desc {
  top: 0;
  left: 0;
  bottom: 0;
  right: 300px;
  position: absolute;

  > div {
    margin-left: 10%;
    margin-top: 10px;
  }

  @media (max-width: 650px) {
    display: none;
  }
}
.socialAxoimg {
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  position: absolute;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );

  @media (max-width: 650px) {
    left: 0;
    width: auto;
  }
}

.social-image {
  max-width: 1000px;
  height: auto;
  display: block;
  margin: auto;
}

@media (min-width: 0px) {
  .social-image {
    max-width: 90%;
  }
}

@media (min-width: 1200px) {
  .social-image {
    max-width: 1000px;
  }
}
