.language-enUS {
  /* line 6, ../sass/_content.sass */
  .offline-ui .offline-ui-retry:before {
    content: "Reconnect";
  }
  /* line 11, ../sass/_content.sass */
  .offline-ui.offline-ui-up .offline-ui-content:before {
    content: "Your computer is connected to the internet.";
  }
  @media (max-width: 1024px) {
    /* line 11, ../sass/_content.sass */
    .offline-ui.offline-ui-up .offline-ui-content:before {
      content: "Your device is connected to the internet.";
    }
  }
  @media (max-width: 568px) {
    /* line 11, ../sass/_content.sass */
    .offline-ui.offline-ui-up .offline-ui-content:before {
      content: "Your device is connected.";
    }
  }
  /* line 22, ../sass/_content.sass */
  .offline-ui.offline-ui-down .offline-ui-content:before {
    content: "Your computer lost its internet connection.";
  }
  @media (max-width: 1024px) {
    /* line 22, ../sass/_content.sass */
    .offline-ui.offline-ui-down .offline-ui-content:before {
      content: "Your device lost its internet connection.";
    }
  }
  @media (max-width: 568px) {
    /* line 22, ../sass/_content.sass */
    .offline-ui.offline-ui-down .offline-ui-content:before {
      content: "Your device isn't connected.";
    }
  }
  /* line 33, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-connecting .offline-ui-content:before,
  .offline-ui.offline-ui-down.offline-ui-connecting-2s
    .offline-ui-content:before {
    content: "Attempting to reconnect...";
  }
  /* line 42, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit="second"]:before {
    content: "Connection lost. Reconnecting in " attr(data-retry-in-value)
      " seconds...";
  }
  @media (max-width: 568px) {
    /* line 42, ../sass/_content.sass */
    .offline-ui.offline-ui-down.offline-ui-waiting
      .offline-ui-content[data-retry-in-unit="second"]:before {
      content: "Reconnecting in " attr(data-retry-in-value) "s...";
    }
  }
  /* line 50, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit="second"][data-retry-in-value="1"]:before {
    content: "Connection lost. Reconnecting in " attr(data-retry-in-value)
      " second...";
  }
  @media (max-width: 568px) {
    /* line 50, ../sass/_content.sass */
    .offline-ui.offline-ui-down.offline-ui-waiting
      .offline-ui-content[data-retry-in-unit="second"][data-retry-in-value="1"]:before {
      content: "Reconnecting in " attr(data-retry-in-value) "s...";
    }
  }
  /* line 58, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit="minute"]:before {
    content: "Connection lost. Reconnecting in " attr(data-retry-in-value)
      " minutes...";
  }
  @media (max-width: 568px) {
    /* line 58, ../sass/_content.sass */
    .offline-ui.offline-ui-down.offline-ui-waiting
      .offline-ui-content[data-retry-in-unit="minute"]:before {
      content: "Reconnecting in " attr(data-retry-in-value) "m...";
    }
  }
  /* line 66, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit="minute"][data-retry-in-value="1"]:before {
    content: "Connection lost. Reconnecting in " attr(data-retry-in-value)
      " minute...";
  }
  @media (max-width: 568px) {
    /* line 66, ../sass/_content.sass */
    .offline-ui.offline-ui-down.offline-ui-waiting
      .offline-ui-content[data-retry-in-unit="minute"][data-retry-in-value="1"]:before {
      content: "Reconnecting in " attr(data-retry-in-value) "m...";
    }
  }
  /* line 74, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit="hour"]:before {
    content: "Connection lost. Reconnecting in " attr(data-retry-in-value)
      " hours...";
  }
  @media (max-width: 568px) {
    /* line 74, ../sass/_content.sass */
    .offline-ui.offline-ui-down.offline-ui-waiting
      .offline-ui-content[data-retry-in-unit="hour"]:before {
      content: "Reconnecting in " attr(data-retry-in-value) "h...";
    }
  }
  /* line 82, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit="hour"][data-retry-in-value="1"]:before {
    content: "Connection lost. Reconnecting in " attr(data-retry-in-value)
      " hour...";
  }
  @media (max-width: 568px) {
    /* line 82, ../sass/_content.sass */
    .offline-ui.offline-ui-down.offline-ui-waiting
      .offline-ui-content[data-retry-in-unit="hour"][data-retry-in-value="1"]:before {
      content: "Reconnecting in " attr(data-retry-in-value) "h...";
    }
  }
  /* line 90, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s.offline-ui-waiting
    .offline-ui-retry {
    display: none;
  }
  /* line 93, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s
    .offline-ui-content:before {
    content: "Connection attempt failed.";
  }
}
