.axoinboxtoolbar {
  margin-top: 5px;
  display: inline-block;

  @media (min-width: 992px) {
    margin-top: 10px;
  }
}

.post-header {
  padding: 12px;
  background-image: linear-gradient(#ececec, #ececec);
  color: #202020;
}

.comment-box {
  padding-left: 5px;
}

.poster-info {
  text-align: left;
  padding-left: 5px;
  display: inline-block;
  vertical-align: middle;

  > div:first-child {
    top: 5px;
    position: relative;
  }

  > div:last-child > small {
    top: -5px;
    position: relative;
  }
}

.post-date {
  float: right;
}

// .inbox-nav {
//   border-right: 0px solid #EEE;
// }

// .inbox-nav .badge {
//   width: 10px;
//   height: 14px;
//   margin-top: -3px;
//   margin-right: 10px;
//   border-radius: 100px;
//   vertical-align: middle;
// }

// .inbox-nav .list-group-item {
//   color: #555;
//   border: none;
//   padding: 10px 5px;
//   margin-top: 0;
//   margin-bottom: 5px;
//   border-radius: 3px !important;

//   &:hover, &:focus {
//     border: none !important;
//   }

//   .badge {
//     top: 0;
//     float: none;
//   }
// }

// .inbox-nav hr {
//   margin-top: -5px;
// }

// .inbox-nav .list-group-item .inbox-item-icon {
//   float: left;
//   font-size: 18px;
//   position: relative;
//   vertical-align: baseline;

//   &[class*="icon-simple-line-"],
//   &[class*="icon-dripicons-"],
//   &[class*="icon-fontello-"] {
//     top: 2px;
//   }

//   & ~ span {
//     position: relative;
//     display: block;
//     line-height: 36px;
//     height: 36px;
//   }
// }

.axoinboxitem {
  color: inherit;
  display: block;
  padding-bottom: 0 !important;
  border-bottom: 1px solid #fff !important;
  background: #ececec;
  &:last-child {
    border-bottom: none !important;
  }

  &.unread {
    background: white;
  }

  &:hover,
  &:focus {
    color: inherit;
    background: rgba(255, 255, 102, 0.2);
  }

  img {
    padding: 2px;
    border-radius: 100px;
    border: 2px solid #eee;
  }
  .axoInboxItemSubjectText {
    font-size: 18px;
  }
  .axoinboxitemSubject > div:first-child {
    top: 2px;
  }
}
