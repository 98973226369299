@-webkit-keyframes ui-spinner-rotate-right {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes ui-spinner-rotate-left {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes ui-spinner-rotate-right {
  0% {
    -moz-transform: rotate(0deg);
  }
  25% {
    -moz-transform: rotate(180deg);
  }
  50% {
    -moz-transform: rotate(180deg);
  }
  75% {
    -moz-transform: rotate(360deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-moz-keyframes ui-spinner-rotate-left {
  0% {
    -moz-transform: rotate(0deg);
  }
  25% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(180deg);
  }
  75% {
    -moz-transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes ui-spinner-rotate-right {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ui-spinner-rotate-left {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* line 118, ../../src/sass/messenger-spinner.scss */
.messenger-spinner {
  position: relative;
  border-radius: 100%;
}
/* line 122, ../../src/sass/messenger-spinner.scss */
ul.messenger.messenger-spinner-active .messenger-spinner .messenger-spinner {
  display: block;
}
/* line 126, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}
/* line 132, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  border-radius: 999px;
  position: absolute;
  width: 100%;
  height: 100%;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
/* line 142, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left {
  left: 0;
}
/* line 145, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left .messenger-spinner-fill {
  left: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-animation-name: ui-spinner-rotate-left;
  -webkit-animation-name: ui-spinner-rotate-left;
  animation-name: ui-spinner-rotate-left;
  -moz-transform-origin: 0 50% 50%;
  -ms-transform-origin: 0 50% 50%;
  -webkit-transform-origin: 0 50% 50%;
  transform-origin: 0 50% 50%;
}
/* line 154, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right {
  left: 50%;
}
/* line 157, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right .messenger-spinner-fill {
  left: -100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-animation-name: ui-spinner-rotate-right;
  -webkit-animation-name: ui-spinner-rotate-right;
  animation-name: ui-spinner-rotate-right;
  -moz-transform-origin: 100% 50% 50%;
  -ms-transform-origin: 100% 50% 50%;
  -webkit-transform-origin: 100% 50% 50%;
  transform-origin: 100% 50% 50%;
}

/* line 15, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  background: #404040;
}
/* line 20, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat.messenger-empty {
  display: none;
}
/* line 23, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat .messenger-message {
  -moz-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13),
    inset 48px 0px 0px #292929;
  -webkit-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13),
    inset 48px 0px 0px #292929;
  box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13),
    inset 48px 0px 0px #292929;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  position: relative;
  border: 0px;
  margin-bottom: 0px;
  font-size: 13px;
  background: transparent;
  color: #f0f0f0;
  font-weight: 500;
  padding: 10px 30px 13px 65px;
}
/* line 35, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat .messenger-message .messenger-close {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #888;
  opacity: 1;
  font-weight: bold;
  display: block;
  font-size: 20px;
  line-height: 20px;
  padding: 8px 10px 7px 7px;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
/* line 51, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat .messenger-message .messenger-close:hover {
  color: #bbb;
}
/* line 54, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat .messenger-message .messenger-close:active {
  color: #777;
}
/* line 57, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat .messenger-message .messenger-actions {
  float: none;
  margin-top: 10px;
}
/* line 61, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat .messenger-message .messenger-actions a {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
  color: #aaa;
  background: #2e2e2e;
  display: inline-block;
  padding: 10px;
  margin-right: 10px;
  padding: 4px 11px 6px;
  text-transform: capitalize;
}
/* line 72, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat .messenger-message .messenger-actions a:hover {
  color: #f0f0f0;
  background: #2e2e2e;
}
/* line 76, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat .messenger-message .messenger-actions a:active {
  background: #292929;
  color: #aaa;
}
/* line 80, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat
  .messenger-message
  .messenger-actions
  .messenger-phrase {
  display: none;
}
/* line 83, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat .messenger-message .messenger-message-inner:before {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 17px;
  display: block;
  content: " ";
  top: 50%;
  margin-top: -8px;
  height: 13px;
  width: 13px;
  z-index: 20;
}
/* line 95, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat
  .messenger-message.alert-success
  .messenger-message-inner:before {
  background: #5fca4a;
}
/* line 98, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat
  .messenger-message.alert-info
  .messenger-message-inner:before {
  background: #61c4b8;
}
/* line 103, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat
  .messenger-message.alert-error
  .messenger-message-inner:before {
  background: #dd6a45;
}
/* line 34, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-flat
  .messenger-message.alert-error.messenger-retry-soon
  .messenger-spinner {
  width: 32px;
  height: 32px;
  background: transparent;
}
/* line 39, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-flat
  .messenger-message.alert-error.messenger-retry-soon
  .messenger-spinner
  .messenger-spinner-side
  .messenger-spinner-fill {
  background: #dd6a45;
  -moz-animation-duration: 20s;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  opacity: 1;
}
/* line 47, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-flat
  .messenger-message.alert-error.messenger-retry-soon
  .messenger-spinner:after {
  content: "";
  background: #292929;
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  display: block;
}
/* line 34, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-flat
  .messenger-message.alert-error.messenger-retry-later
  .messenger-spinner {
  width: 32px;
  height: 32px;
  background: transparent;
}
/* line 39, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-flat
  .messenger-message.alert-error.messenger-retry-later
  .messenger-spinner
  .messenger-spinner-side
  .messenger-spinner-fill {
  background: #dd6a45;
  -moz-animation-duration: 600s;
  -webkit-animation-duration: 600s;
  animation-duration: 600s;
  opacity: 1;
}
/* line 47, ../../src/sass/messenger-spinner.scss */
ul.messenger-theme-flat
  .messenger-message.alert-error.messenger-retry-later
  .messenger-spinner:after {
  content: "";
  background: #292929;
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  display: block;
}
/* line 114, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat
  .messenger-message-slot.messenger-last
  .messenger-message {
  -moz-border-radius: 4px 4px 0px 0px;
  -webkit-border-radius: 4px;
  border-radius: 4px 4px 0px 0px;
  -moz-box-shadow: inset 48px 0px 0px #292929;
  -webkit-box-shadow: inset 48px 0px 0px #292929;
  box-shadow: inset 48px 0px 0px #292929;
}
/* line 118, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat
  .messenger-message-slot.messenger-first
  .messenger-message {
  -moz-border-radius: 0px 0px 4px 4px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 4px 4px;
  -moz-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13),
    inset 48px 0px 0px #292929;
  -webkit-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13),
    inset 48px 0px 0px #292929;
  box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13),
    inset 48px 0px 0px #292929;
}
/* line 122, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat
  .messenger-message-slot.messenger-first.messenger-last
  .messenger-message {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-box-shadow: inset 48px 0px 0px #292929;
  -webkit-box-shadow: inset 48px 0px 0px #292929;
  box-shadow: inset 48px 0px 0px #292929;
}
/* line 126, ../../src/sass/messenger-theme-flat.sass */
ul.messenger-theme-flat .messenger-spinner {
  display: block;
  position: absolute;
  left: 7px;
  top: 50%;
  margin-top: -18px;
  z-index: 999;
  height: 32px;
  width: 32px;
  z-index: 10;
}
