.axoFrontPage {
  @media (min-width: 0px) {
    .top-margin-box {
      margin-top: 115px;
    }
  }

  @media (min-width: 768px) {
    .top-margin-box {
      margin-top: 125px;
    }
  }

  @media (min-width: 992px) {
    .top-margin-box {
      margin-top: 60px;
    }
  }

  @media (min-width: 1200px) {
    .top-margin-box {
      margin-top: 90px;
    }
  }
  @media (min-width: 0px) {
    .top-margin-box-simple {
      margin-top: 45px;
    }
  }

  @media (min-width: 768px) {
    .top-margin-box-simple {
      margin-top: 125px;
    }
  }

  @media (min-width: 992px) {
    .top-margin-box-simple {
      margin-top: 60px;
    }
  }

  @media (min-width: 1200px) {
    .top-margin-box-simple {
      margin-top: 90px;
    }
  }
}
