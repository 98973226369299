.Lex-button-1.FontPageViewNavButton {
  border-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.InfoTextsEasyID {
  padding-left: 5px;
  padding-right: 5px;
  font-weight: normal;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: left;
  line-height: 32px;
  display: block;
  font-size: 16px;
}

.InfoTextsEasyIDBG1 {
  border: 2px solid #cbe6dd;
  color: #000;
}

.overtext {
  color: #286da8;
  height: 50px;
  line-height: 25px;
  font-size: 17px;
  font-weight: bold;
}
.undertext {
  color: #404040;
  height: 30px;
  line-height: 25px;
  font-size: 15px;
}
.Testcolor {
  color: #286da8;
  color: #f7882f;
  color: #d9b310;
  color: #328cc1;
}

.toggleButton {
  background-image: linear-gradient(#e2e9e9, #e2e9e9);
  width: 100%;
  color: #286da8;
  height: 50px;
  width: 100%;
  line-height: 25px;
  font-size: 17px;
  margin: -1px;
}
.ToggleIndhold {
  background-image: linear-gradient(#fff, #fff);
  color: #404040;
  /* width: 900px ; */
  line-height: 25px;
  min-height: 150px;
  margin-bottom: 100px;
}

.axo-icon.toggleicon {
  font-size: 28px;
}
.marginonly {
  margin-bottom: 50px;
}

.axolexeasyidtext {
  color: #f7882f;
}

.Padding50 {
  padding-bottom: 13px;
}
.axolexfooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 9;
  background-color: #23527c;
  color: #ffffff;
  text-align: center;
}

.Axoshadow {
  width: 120px;
  height: 120px;
  border: 2px solid #fff;
  -moz-box-shadow: 0px 6px 5px #ccc;
  -webkit-box-shadow: 0px 6px 5px #f7882f;
  box-shadow: 0px 6px 5px #f7882f;
  -moz-border-radius: 190px;
  -webkit-border-radius: 190px;
  border-radius: 190px;
}

/* border-bottom: 0px solid #751A33; */

.backiconcolor1 {
  border-left: 4px solid #0a0003;
}
.axoSidebarbutton .frontnavbarimg.backiconcolor {
  color: #0a0003;
  padding-left: 10px;
}
.Mailiconcolor1 {
  border-left: 4px solid #751a33;
}
.axoSidebarbutton .frontnavbarimg.Mailiconcolor {
  color: #751a33;
  padding-left: 10px;
}

.Clienticoncolor1 {
  border-left: 4px solid #b34233;
}
.axoSidebarbutton .frontnavbarimg.Clienticoncolor {
  color: #b34233;
  padding-left: 10px;
}
.Caseiconcolor1 {
  border-left: 4px solid #d28f33;
}
.axoSidebarbutton .frontnavbarimg.Caseiconcolor {
  color: #d28f33;
  padding-left: 10px;
}

.Accountingiconcolor1 {
  border-left: 4px solid #d4b95e;
}
.axoSidebarbutton .frontnavbarimg.Accountingiconcolor {
  color: #d4b95e;
  padding-left: 10px;
}
.Invoicesiconcolor1 {
  border-left: 4px solid #4ea2a2;
}
.axoSidebarbutton .frontnavbarimg.Invoicesiconcolor {
  color: #4ea2a2;
  padding-left: 10px;
}
.documenticoncolor1 {
  border-left: 4px solid #1a8693;
}
.axoSidebarbutton .frontnavbarimg.documenticoncolor {
  color: #1a8693;
  padding-left: 10px;
}

.calendariconcolor1 {
  border-left: 4px solid #0066cc;
}
.axoSidebarbutton .frontnavbarimg.calendariconcolor {
  color: #0066cc;
  padding-left: 10px;
}

.socialiconcolor1 {
  border-left: 4px solid #6600cc;
}
.axoSidebarbutton .frontnavbarimg.socialiconcolor {
  color: #6600cc;
  padding-left: 10px;
}
.Adminiconcolor1 {
  border-left: 4px solid #cc00cc;
}
.axoSidebarbutton .frontnavbarimg.Adminiconcolor {
  color: #cc00cc;
  padding-left: 10px;
}
.financeiconcolor1 {
  border-left: 4px solid #4688f4;
}
.axoSidebarbutton .frontnavbarimg.financeiconcolor {
  color: #4688f4;
  padding-left: 10px;
}

.Labelsiconcolor1 {
  border-left: 4px solid #cc6600;
}
.axoSidebarbutton .frontnavbarimg.Labelsiconcolor {
  color: #cc6600;
  padding-left: 10px;
}
.BusinessOperatiniconcolor1 {
  border-left: 4px solid #ff8000;
}
.axoSidebarbutton .frontnavbarimg.BusinessOperatiniconcolor {
  color: #ff8000;
  padding-left: 10px;
}
.Finance1iconcolor11 {
  border-left: 4px solid #cc9900;
}
.axoSidebarbutton .frontnavbarimg.Finance1iconcolor1 {
  color: #cc9900;
  padding-left: 10px;
}

.Applicationiconcolor1 {
  border-left: 4px solid #cc6600;
}
.axoSidebarbutton .frontnavbarimg.Applicationiconcolor {
  color: #cc6600;
  padding-left: 10px;
}

.Axocolor4 {
  color: #ab3910 !important;
}
.axogryicon {
  color: #23527c !important;
}

.LexAccordionPanelody {
  margin-left: 5px;
}

.Bannecolorlogin {
  background-image: linear-gradient(#23527c, #23527c);
  color: #fff;
}

/* .LexAccordionPanelody .Lex-button-1.axoSidebarbutton {
  height:35px!important;
  line-height: 35px!important;
  background:#ffffe6 !important;
  }  */
.LexAccordionPanelody .Lex-button-1.axoSidebarbutton {
  height: 35px !important;
  line-height: 35px !important;
  background: #fff !important;
}
/* .LexAccordionPanelody .Lex-button-1.axoSidebarbutton:hover {
  color:#4CAF50 !important;
  background:#ffffe6 !important; 
  } */
.panel .LexAccordionPanelody .Lex-button-1.axoSidebarbutton:hover {
  color: #005380 !important;
  background: #f2f2f2 !important;
}
.Axocolor1 {
  color: #cc00cc !important;
}
.Axocolor2 {
  color: #00cc00 !important;
}
.Axocolor3 {
  color: #ff9933 !important;
}
.Axocolor4 {
  color: #ab3910 !important;
}
.Axocolor5 {
  color: #f8d210 !important;
}
.axoDropdownButton.VisButton .dropdown button {
  background-image: linear-gradient(#d16ba5, #d16ba5) !important;
}

.Doccolor1 {
  color: #e1465b !important;
}
.Doccolor2 {
  color: #86a8e7 !important;
}
.Doccolor3 {
  color: #009900 !important;
}
.Doccolor4 {
  color: #00e600 !important;
}
.Doccolor5 {
  color: #f96c6c !important;
}
.Doccolor6 {
  color: #ff6666 !important;
}

.frontnavbarimgm {
  font-size: 16px;
}

.panel-group.LexAccordionsidebar .panel-heading {
  height: 50px;
}
.LexAccordionsidebar .panel-body {
  border: 0 px !important;
  margin-left: 10px;
  border-left: 3px solid #4caf50;
  background-color: #fff;
}

/* .LexAccordionsidebar .panel-body {
  border: 0 px !important;
  margin-left: 10px;
  border-left: 3px solid #4CAF50;
  background-color: #ffffe6; } */

.axocircletop {
  background-image: linear-gradient(#f4f2f1, #f4f2f1);
  color: #1c4e80 !important;
}

a:hover,
a:focus {
  color: #23527c;
  text-decoration: none;
}

.mailControlmenu .form-group {
  margin-bottom: 0px;
}

.mailControlmenu .control-label {
  padding-top: 0px !important;
}

.cvrinfotext {
  font-weight: bold !important;
}

.btn-group.FontPageViewNavButton {
  border-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.Lex-button-1.AxoNavnoborder {
  border-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.Lex-Accordion.IndividualPackageDetal .panel-heading:hover a {
  color: #375197 !important;
}

.AxoNavigationheightIcon {
  font-size: 20px;
}
body {
  overflow-y: scroll;
}

.plain {
  padding-top: 0px;
}

.Topmenuimg {
  font-size: 40px !important;
}

.open > .dropdown-menu {
  padding: 0px;
}

.btnSidebarSelect {
  height: 35px;
  width: 100%;
  border-right: 1px solid white;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
  background-image: linear-gradient(#1c4e80, #1c4e80);
}

.btnSidebarSelect:hover {
  border-right: 1px solid white;
  background-image: linear-gradient(#fff, #fff);
  color: #1c4e80 !important;
}

.btnSidebarSelect:active {
  border-right: 1px solid white;
  background-image: linear-gradient(#1c4e80, #1c4e80);
  color: white !important;
}

.Axolexbg {
  background-image: linear-gradient(#ececec, #ececec) !important;
  color: #202020 !important;
}

.axolextopmenuButton {
  background-image: linear-gradient(#ececec, #ececec);
  color: #1c4e80 !important;
  border: 1px !important;
  border-radius: 0px !important;
}

.axolextopmenuButton:hover {
  background-image: linear-gradient(#1c4e80, #1c4e80);
  color: white !important;
  border: 0px !important;
  border-radius: 0px !important;
}
.axolextopmenuButton.active {
  background-image: linear-gradient(#fff, #fff);
  color: #1c4e80 !important;
  border: 0px !important;
  border-radius: 0px !important;
}
.axolextopmenuButton:focus {
  background-image: linear-gradient(#fff, #fff);
  color: #1c4e80 !important;
  border: 0px !important;
  border-radius: 0px !important;
}

.dropdown-menu li > a:hover {
  color: white !important;
}

.axolikeBanne {
  background-image: linear-gradient(white, white) !important;
  color: #202020 !important;
  border-bottom: 1px solid #e6e6e6 !important;
}

.paddingToppx {
  padding-top: 0px;
}

.visButtoncolor {
  background-color: #6cb5f9 !important;
}

.delButtoncolor {
  background-color: #f96c6c !important;
}

/* Removes title and margins on print pages */
@page {
  size: auto;
  margin: 5mm 9mm 3mm 9mm;
}

.pageSection {
  padding-top: 5mm !important;
  padding-left: 4mm;
}

/* print */

@media print {
  .pageSection {
    padding-top: 25mm !important;
    padding-left: 3mm;
  }

  #noprint {
    display: none;
  }

  .noprint {
    display: none !important;
  }
  .footerpagebreak {
    page-break-after: always;
  }
  .employmentContractprint .printpagepadding.linepadding {
    padding-top: 150px !important;
    padding-left: 25px;
  }
  .pagebreakprint {
    page-break-after: always;
  }
  .printTopPadding {
    padding-top: 25px !important;
  }
  .padidingprint.linepadding {
    padding-top: 100px !important;
    padding-left: 50px;
  }
  .printpagepadding {
    padding-top: 100px !important;
    padding-left: 25px;
  }
  .modal-printing body * {
    visibility: hidden;
  }
  .modal-printing #printSection,
  .modal-printing #printSection * {
    visibility: visible;
  }
  .modal-printing #printSection {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0px 0px 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }
}
/* //print */

.Axobt {
  height: 40px;
  font-size: 19px;
  font-weight: normal;
  line-height: 40px;
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #4caf50 !important;
}

.front-navbar-ls div.dropdown > button {
  border-radius: 0px !important;
}

.linepadding div {
  padding-bottom: 8px;
  font-size: 15px;
}

.doubleboder {
  background: rgba(255, 235, 59, 0.33);
  border-bottom: 1px solid #4caf50 !important;
  margin-bottom: -1px;
  text-decoration-style: 1px solid #4caf50 !important;
}

.axolexQRcodebanne.BannecolorQR {
  background-image: linear-gradient(#ececec, #ececec);
  text-align: center;
  line-height: 40px;
  height: 40px;
  width: 100%;
  padding: 0px;
  /* font-size: 19px;
  font-weight: normal !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;  */
}

.BanneDashboard {
  background-image: linear-gradient(#f8f8f8, #ececec);
  color: #1c4e80;
}

.Bannecolor {
  background-image: linear-gradient(#f8f8f8, #ececec);
  border-bottom: 1px solid #e6e6e6 !important;
  border-top: 1px solid #e6e6e6 !important;
}

.Axoaccountsbannebg {
  background-image: linear-gradient(#f8f8f8, #ececec);
}

.borderFormControlfocus:focus {
  border: 1px solid #4caf50 !important;
}

/*<Axolexcheckbox >*/
/* The outer container */
.AxolexcheckboxContainer {
  display: inline-block;
  height: 25px;
  width: 25px;
  margin-left: 3px;
  margin-right: 3px;
}

/* The inner label container */
.Axolexcheckbox {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 19px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.Axolexcheckbox input {
  position: absolute;
  display: none;
}

/* Create a custom checkbox */
.Axolexcheckmark {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 22px;
  width: 22px;
  background-color: #ececec;
  border: 1px solid #4caf50;
}

/* Style the checkmark/indicator */
.Axolexcheckbox .Axolexcheckmark:after {
  left: 7px;
  top: 3px;
  width: 9px;
  height: 15px;
  border: solid #4caf50;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.frontnavbarimg {
  font-size: 22px !important;
}
/* On mouse-over, add a grey background color */
.Axolexcheckbox:hover input ~ .Axolexcheckmark {
  background-color: #fff;
  border: 1px solid #202020;
}

/* When the checkbox is checked, add a blue background */
.Axolexcheckbox input:checked ~ .Axolexcheckmark {
  background-color: #fff;
  border: 1px solid #4caf50;
}

/* Create the checkmark/indicator (hidden when not checked) */
.Axolexcheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.Axolexcheckbox input:checked ~ .Axolexcheckmark:after {
  display: block;
}

almfontcolor {
  font-size: 12px;
  font-color: #005380;
}

/*</Axocheckbox >*/
.axolexunicode {
  font-size: 24px;
}

button.btn-xs,
.btn-group-xs > .btn {
  text-transform: none;
  /* font-weight: normal;
  font-size: 12px; */
}

a.AxoLink {
  color: white;
  /* font-weight: normal;
  font-size: 21px;  */
}

a:link.AxoLink {
  color: white;
}

a:hover.AxoLink {
  color: white;
}

.leaf-shaped-element {
  float: left;
  width: 400px;
  height: 400px;
  shape-outside: url("../svg/051-1.png");
  shape-margin: 15px;
  shape-image-threshold: 0.5;
  background: #009966 url("../svg/linktop2B.jpg");
  mask-image: url("../svg/051-1.png");
}

div.AxolexITbackground {
  background: url("../svg/bg1.png") center;
  background-repeat: initial;
  border: 1px solid #e6e6e6;
}

.Axolexpanneldivcircle {
  width: 400px;
  height: 400px;
  background: url("../svg/bg1.png") center;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}

.Axolexpanneldivcircle1 {
  width: 370px;
  height: 370px;
  background-color: rgba(255, 255, 255, 0.7);
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}

.axolextextbanne {
  height: 45px;
  width: 100%;
  line-height: 35px;
}

.axonopaddingcol {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 15px;
  padding-right: 15px;
  text-align: center;
}

.Axooverskrift {
  /* font-weight: normal;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 21px; */
  color: #202020;
  line-height: 25px;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
}

.Axooverskrifttext {
  /* font-weight: normal;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
  color: #202020;
}

.QRcodesize {
  width: 160px;
  height: 160px;
  border-width: 0px;
  border-color: white;
}

.Axolextextalm {
  /* font-weight: normal;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 15px; */
  color: #202020;
  line-height: 20px;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
}

.axocircle {
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: url("../svg/linktop2B.jpg") center;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  border-width: 3px;
  border-color: white;
}

.axocircle1 {
  width: 90px;
  height: 90px;
  line-height: 90px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  border-width: 3px;
  border-color: white;
  color: white;
  background-color: rgba(255, 255, 255, 0.7);
}

.axoweathertext {
  color: #202020 !important;
  /* font-Weight: normal; */
}

.icon.axoweathertext img {
  border: 1px solid #202020;
}

.caseSharingtimeanduserProfilelist:nth-child(even) {
  background-image: linear-gradient(#fff, #fff);
  font-size: 13px;
}

.caseSharingtimeanduserProfilelist:nth-child(odd) {
  background-image: linear-gradient(#f6f7f8, #f6f7f8);
  font-size: 13px;
}

.Axoaccountsevenlist:nth-child(even) {
  background-image: linear-gradient(#f6f7f8, #f6f7f8);
  height: 45px;
}

.Axoaccountsevenlist:nth-child(odd) {
  background-image: linear-gradient(#fff, #fff);
  height: 45px;
}

.Axoaccountstotalbanne {
  background: rgba(255, 235, 59, 0.33);
  font-size: 13px;
}

body {
  /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px; */
  line-height: 1.78571;
  color: #333333;
  background-color: #fff;
  padding-top: 0px;
}

.axopointer {
  height: 50px;
  width: 180px;
  background: url("../svg/linktop2B.jpg") center;
  border-radius: 80px;
  float: center;
  margin: 7px;
}

.axopointer3 {
  border-radius: 3px;
}

.axopointer1 {
  height: 40px;
  width: 170px;
  background-color: #f0f0f0;
  border-radius: 70px;
  margin: 5px;
  float: center;
}

.axopointer13 {
  border-radius: 3px;
}

.axotpprice {
  background-color: #1c4e80;
  color: white;
}

.nopaddingLeft {
  padding-left: 0px;
}

.nopaddingRight {
  padding-right: 0px;
}

.nopadding {
  padding-left: 0px;
  padding-right: 0px;
}

td.AxoPackagetd {
  padding-left: 0px;
  padding-right: 0px;
  font-size: 15px;
  font-weight: bold;
  line-height: 35px;
}

table.axotable {
  width: 100%;
}
table.axotable td,
table.axotable th {
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
  max-width: 100%;
}

div.nopadding-panel div.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.list-group.no-list-margin {
  margin-bottom: 0px;
}

.editable:focus {
  outline: 1px solid #4caf50;
}

select:focus {
  outline: 1px solid #4caf50;
}

.searchbox:focus {
  outline: 1px solid #4caf50 !important;
}

.editable {
  color: #1253a4;
}

.dropzone.small-margin {
  margin-top: 1px !important;
  margin-bottom: 10px !important;
}

.tablesaw td {
  font-size: 12px;
}

.btn.dropdown-toggle.table-hover {
  height: 10px;
  display: inline-block;
  margin-bottom: 0;
  padding: 0px 0px;
  padding-top: 0px;
}

a:link {
  color: #1253a4;
}

/* mouse over link */
a:hover {
  color: #4caf50;
}

/* selected link */
a:active {
  color: #4caf50;
}

a:visited {
  color: #1253a4;
}

a.Lex-button-2 {
  color: white;
}

a.Lex-button-2:link {
  color: white;
}

/* mouse over link */
a.Lex-button-2:hover {
  color: white;
}

/* selected link */
a.Lex-button-2:active {
  color: white;
}

a.Lex-button-2:visited {
  color: white;
}

@media screen {
  #printSection {
    display: none;
  }
}

.dropzone.our-dropzone {
  background: #a0c7f2;
}

.dz-message.our-dz-message {
  color: white;
}

input[type="text"]:disabled {
  background: #ececec;
}

.panel-group {
  margin-bottom: 0px;
}

div.no-top-margin div.panel-body {
  padding: 0 25px 25px 25px !important;
}

.trumbowyg-box,
.trumbowyg-editor {
  margin: 0px auto;
}

.btn {
  border-radius: 3px;
}

span.attachment-link {
  color: #4caf50;
}

span.attachment-link:hover {
  color: #4caf50;
}

.Axolike {
  background: #f5f5f5 !important;
  color: black;
}

div.center-dialog div.modal-dialog {
  top: 10% !important;
}

.table tr.active td {
  background: lightblue !important;
}

.panel {
  border-top: 0px;
}

.container-fluid.compact {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 0px;
}

.AxoNavigationButtonheightX {
  height: 60px;
}

.slick-list {
  overflow: visible !important;
  margin: 0;
  padding: 0;
}

.slick-dots,
.slick-next,
.slick-prev {
  z-index: 1000 !important;
}

.slick-next:before,
.slick-prev:before {
  color: #1c4e80 !important;
  font-size: 40px !important;
}

.dropdown-menu .active > a,
.dropdown-menu .active > a:hover,
.dropdown-menu .active > a:focus {
  color: #202020;
}

/* Extra small devices (phones, up to 480px) */
@media screen and (max-width: 767px) {
  div.responsive-text {
    font-size: 10px;
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  div.responsive-text {
    font-size: 12px;
  }
}

/* tablets/desktops and up ----------- */
@media (min-width: 992px) and (max-width: 1199px) {
  div.responsive-text {
    font-size: 13px;
  }
}

/* large desktops and up ----------- */
@media screen and (min-width: 1200px) {
  div.responsive-text {
    font-size: 15px;
  }
}

.btn {
  text-transform: none !important;
}

div.dropdown-form-styling ul.dropdown-menu {
  min-width: 300px;
}

.borderless td,
.borderless th {
  border: none !important;
}

.text-m div {
  padding-top: 0px;
  padding-bottom: 4px;
  /* font-weight: normal !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 15px; */
  color: #000000;
  line-height: 25px;
}

.text-L div {
  padding-top: 0px;
  padding-bottom: 4px;
  /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 13px; */
  line-height: 40px;
}

.text-m div b {
  padding-top: 0px;
  padding-bottom: 4px;
  /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 12px; */
  line-height: 25px;
}

.gradient-baggrund {
  background-image: linear-gradient(#ececec, #ececec);
  height: 50px;
  border-top: 0px;
  padding-top: 0px;
  color: #202020;
  border-bottom: 1px solid #e6e6e6 !important;
}

.gradient-baggrund .fixedDataTableLayout_main {
  color: #202020;
}

.gradient-baggrund .case-form {
  color: #202020;
}

.gradient-baggrund10 {
  color: #202020;
}

button.btn.Lex-button {
  background-image: linear-gradient(#fff, #fff);
  color: #f96d00 !important;
  /* font-size: 12px;
  font-weight: normal !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
}

button.btn.Lex-button:hover {
  background-image: linear-gradient(#1c4e80, #1c4e80);
  color: white !important;
}

button.btn.Lex-button.active {
  background-image: linear-gradient(#fff, #fff);
  color: #f96d00;
}

.Lex-Accordion.IndividualPackageDetal .panel-heading {
  background-image: linear-gradient(#ececec, #ececec) !important;
  color: #1c4e80 !important;
  border-bottom: 1px solid #1c4e80 !important;
  margin-bottom: -1px;
}

.Lex-Accordion.IndividualPackageDetal .panel-heading:hover {
  background-image: linear-gradient(#fff, #fff) !important;
  color: #4caf50 !important;
  border-bottom: 1px solid #4caf50 !important;
  margin-bottom: -1px;
}

.axoFrontPage .Lex-Accordion.IndividualPackageDetal .panel-heading:hover a {
  background-image: linear-gradient(#fff, #fff) !important;
  color: #4caf50 !important;
  border-bottom: 1px solid #4caf50 !important;
  margin-bottom: -1px;
}
/* background-Image: linear-gradient(#67abcb, #67abcb) */

.Colorbutton.axocolorbutton .Lex-button-1 {
  border-bottom: 2px solid #33cc33;
}
.Colorbutton.axocolorbutton .Lex-button-1:hover {
  background: rgba(41, 160, 245, 0.129);
  color: #33cc33 !important;
}
.dropdown button:hover {
  background: rgba(41, 160, 245, 0.129);
  color: #177fad !important;
}

.Colorbuttonicon {
  color: #ffffff !important;
  font-size: 25px !important;
}

.Lex-CardFooter {
  background-image: linear-gradient(#ececec, #dcdcdc);
}

.Lex-DropdownButton-menu {
  background-image: linear-gradient(#fff, #ececec);
  border: 0px;
  /* font-weight: normal; */
  height: 45px !important;
  line-height: 45px !important;
  /* font-size: 15px; */
  color: #202020;
  /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;  */
}

.Lex-DropdownButton-menuX {
  background-image: linear-gradient(#fff, #ececec);
  width: 100%;
  border: 2px solid white;
  padding-bottom: 5px;
  /* font-weight: normal; */
  height: 40px !important;
  line-height: 40px !important;
  /* font-size: 15px; */
  color: #202020;
  /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
}

.dropdown-menu li.Lex-DropdownButton-menu > a {
  display: block;
  padding: 5px 5px 5px 7px;
  clear: both;
  border: 1px;
  border-color: white;
  /* font-weight: normal; */
  line-height: 2;
  color: #202020;
  white-space: pre-line;
}

.lex-front-navbar .btn-default:active,
.lex-front-navbar .btn-default.active,
.lex-front-navbar .btn-default[aria-expanded="true"],
.lex-front-navbar .btn-default.active:hover,
.lex-front-navbar .btn-default.active:focus,
.lex-front-navbar .btn-default[aria-expanded="true"]:hover,
.lex-front-navbar .btn-default[aria-expanded="true"]:focus,
.lex-front-navbar .open > .btn-default.dropdown-toggle {
  color: #202020;
  border: 0px;
}

.freeware-dropdown .dropdown-menu {
  width: 100%;
}

.freeware-dropdown .dropdown-menu a {
  width: 100%;
}

.lex-front-navbar .btn-lg,
.lex-front-navbar .btn-group-lg > .btn {
  padding: 10px 0px !important;
}

.lex-front-navbar .dropdown-menu {
  width: 100%;
  min-width: 250px;
}

.Lex-DropdownButton-menu {
  background-image: linear-gradient(#fff, #fff);
  color: #f96d00;
}

.Lex-DropdownButton-menu:hover {
  background-image: linear-gradient(#fffdfb, #fffdfb);
  color: #4caf50 !important;
  border: 0px;
}

.Lex-DropdownButton-menu.active {
  background-image: linear-gradient(#fff, #fff);
  color: #f96d00;
  border: 0px;
}

.Lex-DropdownButton-menuX {
  background-image: linear-gradient(#1c4e80, #1c4e80);
  color: white;
}

.Lex-DropdownButton-menuX:hover {
  background-image: linear-gradient(#fffdfb, #fffdfb);
  color: #4caf50 !important;
  border: 0px;
}

.Lex-DropdownButton-menuX.active {
  background-image: linear-gradient(#a0c7f2, #428bca);
  border: 0px;
  color: white !important;
}

.Lex-DropdownButton-menuX.active {
  background-image: linear-gradient(#1c4e80, #1c4e80);
  color: white;
  border: 0px;
}

.btn.Lex-button-1 {
  background-image: linear-gradient(#1c4e80, #1c4e80);
  border: 0px;
  color: white;
  /* font-size: 14px;
  font-weight: normal !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
  border-radius: 3px;
}

.btn.Lex-button-1:hover {
  background-image: linear-gradient(#fffdfb, #fffdfb);
  color: #f96d00 !important;
  border-radius: 3px;
  /* border: 0px; */
}

.btn.Lex-button-1.active {
  /* font-weight: normal !important; */
  background-image: linear-gradient(#fff, #fff);
  color: #f96d00 !important;
  border: 0px;
  border-radius: 3px;
}

.btn.Lex-button-1.active {
  background-image: linear-gradient(#1c4e80, #1c4e80);
  color: white !important;
  border-radius: 3px;
  /* font-weight: normal !important; */
}

.Lex-button-1.AxoNavigationButtonheight {
  border-radius: 0px !important;
}

.div.Lex-button-1 {
  background-image: linear-gradient(#1c4e80, #1c4e80);
  color: white !important;
  border: 0px;
  /* font-size: 14px; */
  border-radius: 3px;
  /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;  */
}

.div.Lex-button-1:hover {
  background-image: linear-gradient(#fffdfb, #fffdfb);
  color: #f96d00 !important;
  border-radius: 3px;
  border: 0px;
}

.div.Lex-button-1.active {
  background-image: linear-gradient(#1c4e80, #1c4e80);
  color: white !important;
  border: 0px;
  border-radius: 3px;
}

.Lex-button-1 {
  background-image: linear-gradient(#1c4e80, #1c4e80);
  color: white !important;
  border-radius: 3px;
  /* font-size: 14px;
  font-weight: normal !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;  */
}

.Lex-button-1:hover {
  background-image: linear-gradient(#fffdfb, #fffdfb);
  color: #f96d00 !important;
  border-radius: 3px;
  /* font-weight: normal !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
  border: 0px;
}

.Lex-button-1.active {
  background: linear-gradient(#1c4e80, #1c4e80);
  color: white !important;
  border: 0px;
  border-radius: 3px;
  /* font-weight: normal !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;  */
}

.btn-link.lex-dropdown {
  color: #000000;
  /* font-size: 13px;
  font-weight: normal !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;  */
}

.Axo-button-1 {
  background-image: linear-gradient(#fde9df, #fde9df);
  color: #f96d00;
  /* font-size: 16px;
  font-weight: normal !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;  */
}

.Axo-button-1:hover {
  background-image: linear-gradient(#fffdfb, #fffdfb);
  color: #f96d00;
  /* font-weight: normal !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
  /* border: 0px;  */
}

.Axo-button-1.active {
  background: linear-gradient(#f96d00, #f96d00);
  color: #fff;
  border: 0px;
  /* font-weight: normal !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;  */
}

.axo-nav-lg .axonavTab {
  height: 38px;
  line-height: 2px;
}

.dropdown-menu .AxotabDropdownmenu.axonavTab {
  /* font-size: 16px !important;  */
}

.axo-nav .axonavTab {
  height: 38px;
}

.LexNavButton .axonavTab {
  height: 38px;
  line-height: 30px;
}

.axo-nav-lg .btn-lg {
  text-align: center;
  border: 1px solid white !important;
  color: #202020;
  word-spacing: 0px;
  /* font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: normal;
  line-height: 30px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;  */
}

.axo-nav .btn-lg {
  text-align: center;
  color: #202020;
  /* font-size: 12px;
  font-weight: normal !important;
  letter-spacing: 0.5px;
  line-height: 1,5;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;  */
}

.block-dropdown > .dropdown.btn-group {
  display: block;
}

ul.xs-navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style-type: none;
}

ul.xs-navbar > li {
  height: 75px;
  width: 75px;
  border-style: solid;
  border-width: 0px 0px;
  border-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.xs-navbar > li:first-child {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
}

ul.xs-navbar > li:first-child img {
  position: static !important;
}

ul.xs-navbar > li:last-child {
  border-style: solid;
  border-width: 0px 0px 0px 1px;
}

.Axodropdown-menu a.icon {
  position: relative;
  padding-left: 40px;
}

.Axodropdown-menu a.icon .glyphicon {
  top: 6px;
  left: 10px;
  font-size: 17px;
}

.front-navbar div.dropdown {
  width: 100%;
  font-size: 13px;
  color: #202020;
  font-weight: normal !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.front-navbar div.dropdown > button {
  width: 100%;
  font-size: 13px;
  color: #202020;
  font-weight: normal !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.front-navbar-ls div.dropdown {
  width: 100%;
  color: #202020;
  font-weight: normal !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.front-navbar-ls div.dropdown > button {
  width: 100%;
  color: #202020;
  font-weight: normal !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.front-navbar-xs div.dropdown {
  width: 100%;
  font-size: 13px;
  color: #202020;
  font-weight: normal !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.front-navbar-xs div.dropdown > button {
  width: 100%;
  font-size: 13px;
  color: #202020;
  font-weight: normal !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.image-banner {
  box-sizing: border-box;
}
.image-banner *,
.image-banner *::before,
.image-banner *::after {
  position: relative;
  box-sizing: inherit;
}
.image-banner .banner-container {
  display: block;
  width: 100%;
}
.image-banner .banner-item {
  display: block;
  width: 100%;
  overflow: hidden;
  border: 1px solid #fff;
  font-size: 16px;
}
@media screen and (min-width: 640px) {
  .image-banner .banner-item {
    height: 60vw;
    max-height: 480px;
    margin: 0 auto;
    border: 1px solid #fff;
  }
}
.image-banner .bg_img {
  display: block;
  width: 100%;
  height: 60vw;
  object-fit: cover;
}
@media screen and (min-width: 640px) {
  .image-banner .bg_img {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: width 1.3s ease-in-out, height 1.3s ease-in-out;
  }
}
@media screen and (min-width: 640px) {
  .image-banner .banner-item:hover .bg_img {
    width: 110%;
    height: 120%;
  }
}
.image-banner .text_wrapper {
  display: block;
  color: white;
}
@media screen and (max-width: 640px) {
  .image-banner .text_wrapper {
    background: linear-gradient(to right, #270101, #dfdfdf);
  }
}
@media screen and (min-width: 640px) {
  .image-banner .text_wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    background: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.5));
    color: white;
    font-size: 1.25em;
    transition: opacity 0.3s ease-in-out;
  }
  .image-banner .text_wrapper:hover {
    opacity: 0;
  }
}
@media screen and (min-width: 1120px) {
  .image-banner .text_wrapper {
    font-size: 24px;
  }
}
.image-banner .text_wrapper_no_hover {
  display: block;
  color: white;
}
@media screen and (max-width: 640px) {
  .image-banner .text_wrapper_no_hover {
    background: linear-gradient(to right, #270101, #dfdfdf);
  }
}
@media screen and (min-width: 640px) {
  .image-banner .text_wrapper_no_hover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    background: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.5));
    color: white;
    font-size: 1.25em;
    transition: opacity 0.3s ease-in-out;
  }
}
@media screen and (min-width: 1120px) {
  .image-banner .text_wrapper_no_hover {
    font-size: 24px;
  }
}
.image-banner .text_position {
  display: block;
}
@media screen and (min-width: 640px) {
  .image-banner .text_position {
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translateY(-50%);
    width: 70%;
  }
}
@media screen and (min-width: 800px) {
  .image-banner .text_position {
    width: 60%;
    left: 20%;
  }
}
@media screen and (min-width: 960px) {
  .image-banner .text_position {
    width: 50%;
    left: 25%;
  }
}
@media screen and (min-width: 640px) {
  .image-banner .text_position-left {
    left: 5%;
    width: 60%;
  }
}
@media screen and (min-width: 800px) {
  .image-banner .text_position-left {
    width: 40%;
  }
}
@media screen and (min-width: 640px) {
  .image-banner .text_position-right {
    right: 5%;
    left: auto;
    width: 60%;
  }
}
@media screen and (min-width: 800px) {
  .image-banner .text_position-right {
    width: 40%;
  }
}
@media screen and (min-width: 800px) {
  .image-banner .text_position-1-3 {
    left: 5%;
    width: 90%;
  }
}
.image-banner .item--title {
  display: block;
  font-family: "Julius Sans One", Arial, sans-serif;
  font-size: 19px;
  font-weight: 90;
  text-align: left;
  letter-spacing: 0.1em;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media screen and (min-width: 640px) {
  .image-banner .item--title {
    font-size: 45px;
  }
  .image-banner .item--title {
    padding-left: 5px;
  }
}
@media screen and (min-width: 640px) {
  .image-banner .item--title-left {
    text-align: left;
  }
  .image-banner .item--title-right {
    text-align: right;
  }
}
.image-banner .item--text {
  display: block;
  padding: 0 1em;
  margin: 1em 0;
  font-family: "Crimson Text", serif;
  font-size: 13px;
  text-align: justify;
  letter-spacing: 5px;
  color: inherit;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media screen and (min-width: 640px) {
  .image-banner .item--text {
    padding: 0;
    font-size: 18px;
  }
}
@media screen and (min-width: 800px) {
  .image-banner .item--text {
    padding: 0;
    font-size: 25px;
  }
}

div.axotextverticalAlign {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  text-align: left;
  color: #202020;
  position: relative;
  top: 35%;
  transform: perspective(1px) translateY(-50%);
}

div.Axolextextbox {
  line-height: 28px;
  font-weight: normal !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  text-align: left;
  letter-spacing: 0em;
  display: block;
  text-indent: 50px;
  text-align: justify;
  text-justify: inter-word;
  color: #3f3f3b;
}

div.SideHeight {
  min-height: 650px;
}

.lex-front-navbar .dropdown-menu {
  padding: 0px;
}

.visible-danish {
  display: none;
}

.visible-englishUS {
  display: none;
}

.visible-chinese {
  display: none;
}

.AxolexNavigationBars div.dropdown {
  width: 100%;
}

div,
button,
li,
a {
  outline: none !important;
}

.Lex-button-1 .dropdown-menu {
  padding: 0px;
}

.axo-footer div.dropdown {
  width: 100%;
}

.axo-footer div.dropdown button {
  padding-top: 0px;
}

.noradius {
  border-radius: 0px !important;
}

.axoSidebarbutton {
  padding-left: 15px;
  width: 100%;
  text-align: left;
  height: 50px;
  line-height: 50px;
  border-radius: 3px;
  font-size: 16px;
  border: none;
}

.Lex-button-1.axoSidebarbutton {
  background-image: linear-gradient(#ececec, #ececec);
  color: #1c4e80 !important;
}

.Lex-button-1.axoSidebarbutton:hover {
  background-image: linear-gradient(#fffdfb, #fffdfb);
  color: #f96d00 !important;
}

.Lex-button-1.axoSidebarbutton.active {
  background-image: linear-gradient(#fff, #fff);
  color: #f96d00 !important;
}

.Lex-button-1.axoSidebarbutton:visited {
  background-image: linear-gradient(#fff, #fff);
  color: #202020 !important;
}

.AxolexNavigationBars {
  padding-top: 5px;
  border-radius: 3px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.AxoNavigationfonttaxt {
  font-size: 15px;
  font-weight: normal !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.AxolexNavigationBars div.dropdown {
  width: 100%;
}

div.testbackground {
  background: url("../svg/linktop5.jpg") center;
  border: 1px solid #e6e6e6;
}

.TopMenuLinknav {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border: 0px;
}

.TopMenuLinknav th {
  height: 50px;
  text-align: center;
  border: 0px;
}

.axoFrontPage .carousel-caption {
  position: absolute;
  left: 10%;
  right: 10%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #004b8d;
  text-align: center;
  text-shadow: none;
  font-weight: normal !important;
}

.backgroundlineargradient3 {
  background-image: linear-gradient(white, white) !important;
}

.backgroundlineargradient1 {
  background-image: linear-gradient(#f1f1f1, #f1f1f1) !important;
}

.backgroundModalHeaderFooter {
  background-image: linear-gradient(#fffdfb, #fffdfb) !important;
  color: #202020 !important;
}

.sidebar.sidebar__main > div {
  height: 100%;
  overflow-y: scroll;
}

.panel-group .panel + .panel {
  margin-top: 0px;
}

.Lex-Accordion .panel-heading {
  background-image: linear-gradient(#f7f7f7, #f7f7f7) !important;
  color: #202020 !important;
}

.Lex-Accordion .panel-heading a {
  background-image: linear-gradient(#f7f7f7, #f7f7f7) !important;
  color: #202020;
}

.Lex-Accordion .panel-heading:hover {
  background-image: linear-gradient(#fffdfb, #fffdfb) !important;
  color: #202020 !important;
}

.Lex-Accordion a:hover {
  color: green !important;
}

.Lex-Accordion .panel-heading.active {
  background-image: linear-gradient(#fff, #fff) !important;
  color: #1c4e80 !important;
}

.Lex-Accordion a:active {
  color: #1c4e80 !important;
}

.Lex-Accordion.Toggletitel .panel-heading {
  background-image: linear-gradient(#d2edf9, #a5dcf3) !important;
  color: #0d77b7 !important;
  line-height: 1, 7;
  font-weight: bold;
  height: 50px;
}

.Lex-Accordion.Toggletitel .panel-heading:hover {
  background-image: linear-gradient(
    rgba(41, 160, 245, 0.129),
    rgba(41, 160, 245, 0.129)
  ) !important;
  color: #0d77b7 !important;
}

.Lex-Accordion.Toggletitel .panel-heading.active {
  background-image: linear-gradient(
    rgba(41, 160, 245, 0.129),
    rgba(41, 160, 245, 0.129)
  );
  color: #0d77b7 !important;
  height: 35px;
}
.Lex-Accordion.Toggletitel .panel-heading a {
  background-image: linear-gradient(#1a96cb, #1785b5);
  color: #fff !important;
  font-size: 18px;
}

.Lex-Accordion.Toggletitel .panel-heading a.active {
  color: #0d77b7 !important;
}
.Lex-Accordion.Toggletitel .panel-heading a:hover {
  background-image: linear-gradient(
    rgba(41, 160, 245, 0.129),
    rgba(41, 160, 245, 0.129)
  ) !important;
  color: #0d77b7 !important;
}

.Accordionbackgroundmailmenu .panel-heading {
  background-image: linear-gradient(#fff, #fff) !important;
  color: #1c4e80 !important;
}

.Accordionbackgroundmailmenu .panel-heading:hover {
  background-image: linear-gradient(#fffdfb, #fffdfb) !important;
  color: #202020 !important;
}

.Accordionbackgroundmailmenu a:hover {
  color: green !important;
}

.Accordionbackgroundmailmenu .panel-heading.active {
  background-image: linear-gradient(#fff, #fff) !important;
  color: #1c4e80 !important;
}

.Accordionbackgroundmailmenu a:active {
  color: #1c4e80 !important;
}

.axogrouplist > div:nth-child(even) {
  background-image: linear-gradient(#fff, #fff);
  color: #202020 !important;
}

.axogrouplist > div:nth-child(odd) {
  background-image: linear-gradient(#ececec, #ececec);
  color: #202020 !important;
}

.axogrouplist > div:hover {
  background: #f4f4f4 !important;
  color: #1c4e80 !important;
}
.axogrouplist div.active {
  background: #fff !important;
  color: #009933;
}

.axolexwebsideicon1 a {
  color: #202020 !important;
}

.axolexwebsideicon {
  color: #1c4e80;
}

.axolexwebsideicon a:hover {
  color: #202020 !important;
}

.axolexwebsideicon a {
  color: #1c4e80 !important;
}

.axolexwebsideicon a:active {
  color: #1c4e80 !important;
}

.dropdown-menu li > a {
  color: white;
}

.dropdown-menu li > a:hover {
  color: #202020;
}

.panel-folder-list .panel-body {
  padding: 0px;
}

.AXOBG {
  background-color: #ececec;
  font-weight: bold !important;
}

.axogreen {
  color: #009933;
}

.axoblue {
  color: #1253a4;
  background-color: white;
}

.edi {
  background-color: white;
  color: #1253a4;
  font-weight: bold;
}

.axocblueabanne {
  background: #e6e6e6;
  color: #202020;
}

.axooverblue {
  background: None;
  color: #1a1a1a;
}

.axowhite {
  color: white;
}

.axoinvoicebanne {
  background: white !important;
  color: #202020;
}

.dropdown button.noboder.Axolextopbtwidth {
  border-bottom: 0px !important;
}

.axopaddingLeftSocial {
  padding-left: 15px;
  padding-right: 5px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.axoblack {
  color: #202020;
}

.axored {
  color: #ff0000;
}

.text-m .axored {
  color: #ff0000;
}

.bgcolorone {
  background: #ffffcc;
}

.bgcolortwo {
  background: #dce7ef;
}
.bgcolorthree {
  background: #bbbf95;
}

.bgcolorfour {
  background: #fff;
  border: 1px solid #1253a4;
}

.Caselistdoc {
  background: #cbe1e7;
}
.Caselisevent {
  background: #fffbf0;
}

.CaseliseventtimeSpent {
  background: #dff5ec;
}

.CaseVersionListItem {
  min-height: 50px;
  border-bottom: 1px solid #ffffff;
  margin-bottom: -1px;
}

.CaseVersionListItem:hover {
  background-image: linear-gradient(#f6f7f8, #f6f7f8);
  color: rgb(1, 14, 1);
}

.CaseVersionListItem.active {
  border-bottom: 1px solid rgb(247, 243, 207);
  margin-bottom: -1px;
  color: rgb(2, 19, 2);
}

.CaseVersionList .CaseVersionListContent {
  background: white;
}

.Unicodesize {
  font-size: 30px !important;
}

.color-box {
  margin: 2px;
  border: 1px solid #202020;
  width: 25px;
  height: 25px;
}

.color-box.active {
  outline: 1px solid #009933 !important;
  border: 1px solid white;
}

.axo-dropdown .dropdown {
  width: 100%;
}

.axo-dropdown .dropdown-menu {
  width: 100%;
}

.option-box {
  padding: 2px;
  border: 1px solid #009933;
  height: 35px;
  line-height: 25px;
  margin: 10px;
  padding: 5px;
}

.option-box.active {
  outline: 1px solid #009933 !important;
  margin-bottom: -1px;
}

iframe.seamless {
  background-color: transparent;
  border: 0px none transparent;
  padding: 0px;
  overflow: hidden;
  display: block;
  width: 100%;
  overflow: hidden;
}

.Chfontcssbanne.Axobold {
  font-weight: bold !important;
}

.drag-drop-placeholder {
  height: 1px !important;
  background: green !important;
}

.TimeButtoncolor {
  background-image: linear-gradient(#1c4e80, #1c4e80);
  color: white;
}

.Addicon {
  font-size: 16px;
  color: #ffffff !important;
}

@media (min-width: 0px) {
  div.axopaddingLeftxs {
    margin-left: 0px;
  }
  .AccountingpaddingTB {
    padding-left: 5px;
    padding-right: 5px;
  }

  .AxolegalpaddingLeft {
    padding-left: 5px;
  }
  .footerApS {
    text-align: right;
    font-size: 11px;
  }
  .Axooverskrifttext {
    font-size: 15px;
  }
  div.marginRighttextbox {
    margin-right: 8px;
  }
  div.marginLefttextbox {
    margin-left: 8px;
  }
  div.hiddenxsfontSize {
    font-size: 13px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.PageaxolexpaddingLeft {
    margin-left: 35px;
    padding-top: 10px;
  }
  div.Axolextextbox {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 28px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    text-align: left;
    letter-spacing: 0em;
    display: block;
    text-indent: 50px;
    text-align: justify;
    text-justify: inter-word;
  }
  .mailboxpadding {
    padding-left: 0px;
  }
  .AxoNavigationheightIcon1 {
    font-size: 17px;
  }
  .AxoNavigationButtonheight {
    height: 70px;
  }
  .AxoNavigationButtonheight img {
    width: 40px;
    height: 40px;
  }
  .linklogoheight {
    height: 40px;
    line-height: 40px;
  }
  .AxoNavigationButtonheight1 {
    height: 40px;
  }
  .AxoNavigationButtonfooter {
    height: 45px;
  }

  .websiteLogin {
    outline: 5px solid white !important;
  }

  .Loginout {
    font-size: 13px;
    font-weight: normal;
  }
  .FontPageViewNavButton {
    border-radius: 0px;
    height: 54px;
    padding: 0px;
    color: #202020;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .frontnavbarimg {
    font-size: 22px !important;
  }
  .Logosize {
    width: 50px;
    text-align: center;
  }
  .front-navbar-ls div.dropdown {
    height: 54px;
    border-radius: 3px;
    font-size: 15px;
    color: #202020;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .front-navbar-ls div.dropdown > button {
    height: 54px;
    border-radius: 0px;
    font-size: 15px;
    color: #202020;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.frontPageaxolexdiv {
    text-align: center;
    line-height: 40px;
    height: 40px;
    width: 100%;
    padding: 0px;
    font-size: 19px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.frontPageaxolexdivtextbox {
    text-align: left;
    line-height: 27px;
    font-size: 13px;
    max-width: 300px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .axoimg-responsive {
    padding: 0;
    max-width: 100%;
    height: 350px;
    display: inline-block;
    text-align: center;
  }
  .axosmAnchorOffSet {
    visibility: hidden;
    position: relative;
    bottom: 95px;
  }
  .axosmAnchorOffSet1 {
    visibility: hidden;
    position: relative;
    bottom: 95px;
  }
  .axosmAnchorOffSet2 {
    visibility: hidden;
    position: relative;
    bottom: 95px;
  }
  .lismailinbox {
    line-height: 46px;
    font-size: 13px;
  }
  .Lex-Grid-tabl th {
    font-weight: "normal" !important;
    font-size: 12px;
    text-align: left;
    line-height: 26px;
    color: #202020;
    border: none !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .Lex-Grid-tabl td {
    font-weight: "normal" !important;
    border: none !important;
    font-size: 13px;
    line-height: 26px;
    text-align: left;
    color: #202020;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }
  .Lex-Grid-tabl-1 th {
    font-weight: "normal" !important;
    font-size: 12px;
    text-align: left;
    line-height: 26px !important;
    padding-top: 3px;
    color: #202020;
    border-bottom: 1px;
    margin-bottom: -1px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .Lex-Grid-tabl-1 td {
    border-bottom: 1px;
    margin-bottom: -1px;
    font-weight: "normal" !important;
    font-size: 13px !important;
    line-height: 26px !important;
    padding-top: 3px;
    text-align: left;
    color: #202020;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }
  .tableCaseInlineEdit {
    font-weight: "normal" !important;
    font-size: 12px !important;
    text-align: left;
  }
  .axotabletextlebelinbox th {
    font-size: 13px;
  }
  .axotabletextlebelinbox td {
    font-size: 13px;
  }
  .bodyxlink {
    font-size: 13px;
    max-width: 100%;
    margin: auto;
    padding: 15px, 0px, 15px, 0px;
  }
  .Listimgbox {
    display: block;
    max-height: 125px;
    max-width: 100%;
  }
  .Listimgboxheight {
    height: 126px;
  }
  div.testtransbox {
    margin: 1%;
    background-color: rgba(255, 255, 255, 0.6);
    border: 1px solid #e6e6e6;
    filter: alpha(opacity=60);
    /* For IE8 and earlier */
  }
  div.testtransbox p {
    margin: 1%;
    font-weight: bold;
    color: #000000;
  }
  .TopMenuLinknav {
    font-size: 15px;
  }
  .axolexCarouselItem {
    font-size: 15px;
    line-height: 2em;
    font-weight: normal !important;
  }
  .axopaddingLeft {
    padding-left: 0px;
  }
  .axogrouplist > div:nth-child(even) {
    height: 30px;
    line-height: 30px;
    margin: 2px;
    cursor: pointer;
    font-size: 13px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .axogrouplist > div:nth-child(odd) {
    height: 30px;
    line-height: 30px;
    margin: 2px;
    cursor: pointer;
    font-size: 13px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .axogrouplist > div.lismailinbox:nth-child(even) {
    background: white !important;
  }
  .axogrouplist > div.lismailinbox:nth-child(even):hover {
    background: #f4f4f4 !important;
    color: #1c4e80 !important;
  }
  .axogrouplist > div.lismailinbox:nth-child(odd) {
    background: white !important;
  }
  .axogrouplist > div.lismailinbox:nth-child(odd):hover {
    background: #f4f4f4 !important;
    color: #1c4e80 !important;
  }

  div.frontbannetaxt {
    font-size: 28px;
  }
  .axolexQRcodeupload {
    height: 125px;
  }
  .Lex-button-1xlink {
    height: 55px;
  }
  .Lex-button-1xlink.Topmenuxlink {
    height: 90px;
    border-left: 1px;
  }
  .Axolextopbtwidth {
    width: 70px;
  }
  .Axolextopbtwidth1 {
    width: 65px;
  }
  .AttachmentSummarya {
    margin-right: 1%;
  }
}
@media (min-width: 0px) {
  .minHeightside {
    min-height: 100px;
  }
}
@media (min-width: 700px) {
  .minHeightside {
    min-height: 400px;
  }
}
@media (min-width: 900px) {
  .minHeightside {
    min-height: 500px;
  }
}
@media (min-width: 1200px) {
  .minHeightside {
    min-height: 500px;
  }
}
.MenueasyID {
  background: #ffffff;
  min-width: 80px;
  border-bottom: 2px double #f7882f;
}

@media (min-width: 768px) {
  div.axopaddingLeftxs {
    margin-left: 50px;
  }
  .Axooverskrifttext {
    font-size: 17px;
  }
  .Lex-button-1xlink.Topmenuxlink {
    height: 90px;
  }
  .AccountingpaddingTB {
    padding-left: 10px;
    padding-right: 10px;
  }
  div.marginRighttextbox {
    margin-right: 15px;
  }
  div.marginLefttextbox {
    margin-left: 15px;
  }
  div.hiddenxsfontSize {
    font-size: 13px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.PageaxolexpaddingLeft {
    margin-left: 50px;
    padding-top: 10px;
  }
  div.Axolextextbox {
    padding-left: 10px;
    padding-right: 10px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: left;
    display: block;
    text-indent: 50px;
    text-align: justify;
    text-justify: inter-word;
    color: #555;
    font-size: 15px;
    line-height: 1.8em;
    margin-bottom: 0.8em;
  }
  .footerApS {
    text-align: center;
    font-size: 13px;
  }
  .mailboxpadding {
    padding-left: 25px;
  }

  .AxoNavigationheightIcon1 {
    font-size: 19px;
  }
  .AxoNavigationButtonheight {
    height: 70px;
  }
  .linklogoheight {
    height: 70px;
    line-height: 70px;
  }
  .AxoNavigationButtonheight1 {
    height: 50px;
  }
  .AxoNavigationButtonfooter {
    height: 45px;
  }
  .AxoNavigationButtonheight img {
    width: 40px;
    height: 40px;
  }
  .Loginout {
    font-size: 13px;
    font-weight: normal;
  }
  .FontPageViewNavButton {
    border-radius: 0px;
    height: 60px;
    padding: 0px;
    color: #202020;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .frontnavbarimg {
    font-size: 24px !important;
  }
  .front-navbar-ls div.dropdown {
    height: 60px;
    border-radius: 3px;
    font-size: 13px;
    color: #202020;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .Logosize {
    width: 60px;
  }
  .front-navbar-ls div.dropdown > button {
    border-radius: 0px;
    height: 60px;
    font-size: 13px;
    color: #202020;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.frontPageaxolexdiv {
    text-align: center;
    line-height: 50px;
    height: 50px;
    width: 100%;
    padding: 0px;
    font-size: 15px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.frontPageaxolexdivtextbox {
    text-align: left;
    line-height: 30px;
    font-size: 13px;
    max-width: 350px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .axoimg-responsive {
    padding: 0;
    max-width: auto;
    height: 400px;
    display: inline-block;
    text-align: center;
  }
  .axosmAnchorOffSet {
    visibility: hidden;
    position: relative;
    bottom: 100px;
  }
  .axosmAnchorOffSet1 {
    visibility: hidden;
    position: relative;
    bottom: 100px;
  }
  .axosmAnchorOffSet2 {
    visibility: hidden;
    position: relative;
    bottom: 100px;
  }
  .lismailinbox {
    line-height: 46px;
    font-size: 13px;
  }
  .Lex-Grid-tabl th {
    font-weight: "normal" !important;
    font-size: 13px;
    text-align: left;
    line-height: 29px;
    color: #202020;
    border: none !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    padding-left: 2x;
    padding-right: 0px;
  }
  .Lex-Grid-tabl td {
    font-weight: "normal" !important;
    border: none !important;
    font-size: 14px;
    line-height: 29px;
    text-align: left;
    color: #202020;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }
  .Lex-Grid-tabl-1 th {
    font-size: 13px;
    text-align: left;
    line-height: 29px !important;
    padding-top: 3px;
    color: #202020;
    border-bottom: 1px;
    margin-bottom: -1px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    padding-left: 2px;
    padding-right: 0px;
  }
  .Lex-Grid-tabl-1 td {
    border-bottom: 1px;
    margin-bottom: -1px;
    font-weight: "normal" !important;
    font-size: 13px !important;
    line-height: 29px !important;
    padding-top: 3px;
    text-align: left;
    color: #202020;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }
  .tableCaseInlineEdit {
    font-weight: "normal" !important;
    font-size: 13px !important;
    text-align: left;
  }
  .axotabletextlebelinbox th {
    font-size: 13px;
  }
  .axotabletextlebelinbox td {
    font-size: 13px;
  }
  .bodyxlink {
    font-size: 15px;
    max-width: 100%;
    margin: auto;
    padding: 15px, 0px, 15px, 0px;
  }
  .Listimgbox {
    display: block;
    max-height: 148px;
    max-width: 100%;
  }
  .Listimgboxheight {
    height: 150px;
  }
  div.testtransbox {
    margin: 1%;
    background-color: rgba(255, 255, 255, 0.6);
    border: 1px solid #e6e6e6;
    filter: alpha(opacity=60);
    /* For IE8 and earlier */
  }
  div.testtransbox p {
    margin: 1%;
    font-weight: bold;
    color: #000000;
  }
  .TopMenuLinknav {
    font-size: 15px;
  }
  .axolexCarouselItem {
    font-size: 19px;
    font-weight: normal !important;
  }
  .axopaddingLeft {
    padding-left: 0px;
  }
  .AxolegalpaddingLeft {
    padding-left: 10px;
  }
  .axogrouplist > div:nth-child(even) {
    height: 35px;
    line-height: 35px;
    margin: 2px;
    cursor: pointer;
    font-size: 13px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .axogrouplist > div:nth-child(odd) {
    height: 35px;
    line-height: 35px;
    margin: 2px;
    cursor: pointer;
    font-size: 13px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.frontbannetaxt {
    font-size: 36px;
  }
  .axolexQRcodeupload {
    height: 265px;
  }
  .Axolextopbtwidth {
    width: 80px;
  }
  .Axolextopbtwidth1 {
    width: 65;
  }
  .AttachmentSummarya {
    margin-right: 2%;
  }
}

@media (min-width: 992px) {
  div.axopaddingLeftxs {
    margin-left: 200px;
  }
  .Axooverskrifttext {
    font-size: 19px;
  }
  .AccountingpaddingTB {
    padding-left: 25px;
    padding-right: 25px;
  }
  .footerApS {
    text-align: center;
    font-size: 13px;
  }
  div.marginRighttextbox {
    margin-right: 20px;
  }
  div.marginLefttextbox {
    margin-left: 20px;
  }
  div.hiddenxsfontSize {
    font-size: 13px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.PageaxolexpaddingLeft {
    margin-left: 100px;
    padding-top: 10px;
  }
  .mailboxpadding {
    padding-left: 35px;
  }
  .AxoNavigationheightIcon1 {
    font-size: 20px;
  }
  .AxoNavigationButtonheight {
    height: 70px;
  }
  .Lex-button-1xlink.Topmenuxlink {
    height: 90px;
  }
  .linklogoheight {
    height: 50px;
    line-height: 50px;
  }
  .AxoNavigationButtonheight1 {
    height: 50px;
  }
  .AxoNavigationButtonfooter {
    height: 45px;
  }
  .AxoNavigationButtonfooter1 {
    height: 58px !important;
    margin-bottom: 0px !important;
  }
  .AxoNavigationButtonheight img {
    width: 40px;
    height: 40px;
  }
  .Loginout {
    font-size: 15px;
    font-weight: normal;
  }
  .FontPageViewNavButton {
    border-radius: 0px;
    height: 70px;
    padding: 0px;
    color: #202020;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .front-navbar-ls div.dropdown {
    height: 70px;
    border-radius: 3px;
    color: #202020;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .Logosize {
    width: 70px;
  }
  .frontnavbarimg {
    font-size: 25px !important;
  }
  .front-navbar-ls div.dropdown > button {
    height: 70px;
    border-radius: 0px;
    font-size: 15px;
    color: #202020;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.frontPageaxolexdiv {
    text-align: center;
    line-height: 60px;
    height: 60px;
    width: 100%;
    padding: 0px;
    font-size: 21px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.frontPageaxolexdivtextbox {
    text-align: left;
    line-height: 35px;
    font-size: 15px;
    max-width: 400px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .axoimg-responsive {
    padding: 0;
    max-width: auto;
    height: 400px;
    display: inline-block;
  }
  .axosmAnchorOffSet {
    visibility: hidden;
    position: relative;
    bottom: 60px;
  }
  .axosmAnchorOffSet1 {
    visibility: hidden;
    position: relative;
    bottom: 60px;
  }
  .axosmAnchorOffSet2 {
    visibility: hidden;
    position: relative;
    bottom: 60px;
  }
  .lismailinbox {
    line-height: 46px;
    font-size: 13px;
  }
  .Lex-Grid-tabl th {
    font-weight: "normal" !important;
    font-size: 13px;
    text-align: left;
    line-height: 32px;
    color: #202020;
    border: none !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    padding-left: 2px;
    padding-right: 0px;
  }
  .Lex-Grid-tabl td {
    font-weight: "normal" !important;
    border: none !important;
    font-size: 15px;
    line-height: 32px;
    text-align: left;
    color: #202020;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }
  .Lex-Grid-tabl-1 th {
    font-size: 13px;
    text-align: left;
    line-height: 32px !important;
    padding-top: 3px;
    color: #202020;
    border-bottom: 1px;
    margin-bottom: -1px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    padding-left: 2px;
    padding-right: 0px;
  }
  .Lex-Grid-tabl-1 td {
    border-bottom: 1px;
    margin-bottom: -1px;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 32px !important;
    padding-top: 3px;
    text-align: left;
    color: #202020;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }
  .tableCaseInlineEdit {
    font-weight: normal !important;
    font-size: 14px !important;
    text-align: left;
  }
  .axotabletextlebelinbox th {
    font-size: 13px;
  }
  .axotabletextlebelinbox td {
    font-size: 15px;
  }
  .bodyxlink {
    font-size: 13px;
    max-width: 100%;
    margin: auto;
    padding: 15px, 0px, 15px, 0px;
  }
  .Listimgbox {
    display: block;
    max-height: 148px;
    max-width: 100%;
  }
  .Listimgboxheight {
    height: 150px;
  }
  div.testtransbox {
    margin: 1%;
    background-color: rgba(255, 255, 255, 0.6);
    border: 1px solid #e6e6e6;
    filter: alpha(opacity=60);
    /* For IE8 and earlier */
  }
  div.testtransbox p {
    margin: 1%;
    font-weight: bold;
    color: #000000;
  }
  .TopMenuLinknav {
    font-size: 19px;
  }
  .axolexCarouselItem {
    font-size: 21px;
    font-weight: normal !important;
  }
  .axopaddingLeft {
    padding-left: 50px;
  }
  AxolegalpaddingLeft {
    padding-left: 30px;
  }
  .axogrouplist > div:nth-child(even) {
    height: 40px;
    line-height: 40px;
    margin: 2px;
    cursor: pointer;
    font-size: 13px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .axogrouplist > div:nth-child(odd) {
    height: 40px;
    line-height: 40px;
    margin: 2px;
    cursor: pointer;
    font-size: 13px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.frontbannetaxt {
    font-size: 45px;
  }
  div.frontbannetaxt1 {
    padding-left: 50px;
    padding-top: 50px;
  }
  .axolexQRcodeupload {
    height: 265px;
  }
  .nopaddingmailrow {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .Axolextopbtwidth {
    width: 90px;
  }
  .Axolextopbtwidth1 {
    width: 70px;
  }
  .AttachmentSummarya {
    margin-right: 5%;
  }
}

@media (min-width: 1200px) {
  div.axopaddingLeftxs {
    margin-left: 200px;
  }

  .AttachmentSummarya {
    margin-right: 10%;
  }

  .footerApS {
    text-align: center;
    font-size: 13px;
  }
  .Lex-button-1xlink.Topmenuxlink {
    height: 90px;
  }
  .AccountingpaddingTB {
    padding-left: 25px;
    padding-right: 25px;
  }
  .Axooverskrifttext {
    font-size: 21px;
  }
  div.marginRighttextbox {
    margin-right: 25px;
  }
  div.marginLefttextbox {
    margin-left: 25px;
  }
  div.hiddenxsfontSize {
    font-size: 15px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.PageaxolexpaddingLeft {
    margin-left: 230px;
    padding-top: 10px;
  }
  .mailboxpadding {
    padding-left: 35px;
  }
  div.frontbannetaxt {
    font-size: 65px;
  }
  .AxoNavigationheightIcon1 {
    font-size: 20px;
  }
  .AxoNavigationButtonheight {
    height: 90px;
  }
  .linklogoheight {
    height: 90px;
    line-height: 90px;
  }
  .AxoNavigationButtonheight1 {
    height: 60px;
  }
  .AxoNavigationButtonfooter {
    height: 45px;
  }
  .AxoNavigationButtonfooter1 {
    height: 58px !important;
    margin-bottom: 0px !important;
  }

  .AxoNavigationButtonheight img {
    width: 40px;
    height: 40px;
  }
  .Loginout {
    font-size: 13px;
    font-weight: normal;
  }
  .FontPageViewNavButton {
    border-radius: 0px;
    height: 90px;
    padding: 0px;
    color: #202020;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .front-navbar-ls div.dropdown {
    height: 90px;
    font-size: 15px;
    color: #202020;
    border-radius: 3px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .frontnavbarimg {
    font-size: 25px !important;
  }
  .front-navbar-ls div.dropdown > button {
    border-radius: 0px;
    height: 90px;
    font-size: 15px;
    color: #202020;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .Logosize {
    width: 90px;
  }
  div.frontPageaxolexdiv {
    text-align: center;
    line-height: 80px;
    height: 80px;
    width: 100%;
    padding: 0px;
    font-size: 25px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  div.frontPageaxolexdivtextbox {
    text-align: left;
    line-height: 35px;
    font-size: 15px;
    max-width: 400px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .AxolexAxolexfrontPage4 {
    text-align: left;
    line-height: 24px;
    font-size: 15px;
    max-width: 400px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .axoimg-responsive {
    padding: 0;
    max-width: auto;
    height: 450px;
    display: inline-block;
  }
  .axosmAnchorOffSet {
    visibility: hidden;
    position: relative;
    bottom: 90px;
  }
  .axosmAnchorOffSet1 {
    visibility: hidden;
    position: relative;
    bottom: 90px;
  }
  .axosmAnchorOffSet2 {
    visibility: hidden;
    position: relative;
    bottom: 90px;
  }
  .lismailinbox {
    line-height: 46px;
    font-size: 13px;
  }
  .Lex-Grid-tabl th {
    font-weight: "normal" !important;
    font-size: 13px;
    text-align: left;
    line-height: 36px;
    color: #202020;
    border: none !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    padding-left: 5px;
    padding-right: 0px;
  }
  .Lex-Grid-tabl td {
    font-weight: "normal" !important;
    border: none !important;
    font-size: 13px;
    line-height: 36px;
    text-align: left;
    color: #202020;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }
  .Lex-Grid-tabl-1 th {
    font-size: 13px;
    text-align: left;
    line-height: 36px !important;
    padding-top: 3px;
    color: #202020;
    border-bottom: 1px;
    margin-bottom: -1px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    padding-left: 5px;
    padding-right: 0px;
  }
  .Lex-Grid-tabl-1 td {
    border-bottom: 1px;
    margin-bottom: -1px;
    font-weight: "normal" !important;
    font-size: 13px !important;
    line-height: 36px !important;
    padding-top: 3px;
    text-align: left;
    color: #202020;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }
  .tableCaseInlineEdit {
    font-weight: "normal" !important;
    font-size: 13px !important;
    text-align: left;
  }
  .axotabletextlebelinbox th {
    font-size: 13px;
  }
  .axotabletextlebelinbox td {
    font-size: 13px;
  }
  .bodyxlink {
    font-size: 13px;
    max-width: 100%;
    margin: auto;
    padding: 15px, 0px, 15px, 0px;
  }
  .Listimgbox {
    display: block;
    max-height: 148px;
    max-width: 100%;
  }
  .Listimgboxheight {
    height: 150px;
  }
  div.testtransbox {
    margin: 1%;
    background-color: rgba(255, 255, 255, 0.6);
    border: 1px solid #e6e6e6;
    filter: alpha(opacity=60);
    /* For IE8 and earlier */
  }
  div.testtransbox p {
    margin: 1%;
    font-weight: bold;
    color: #000000;
  }
  .TopMenuLinknav {
    font-size: 21px;
    font-weight: "normal";
  }
  .axolexCarouselItem {
    font-size: 23px;
    font-weight: normal !important;
  }
  .axopaddingLeft {
    padding-left: 100px;
  }
  .AxolegalpaddingLeft {
    padding-left: 50px;
  }
  .axogrouplist > div:nth-child(even) {
    height: 45px;
    line-height: 45px;
    margin: 2px;
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .axogrouplist > div:nth-child(odd) {
    height: 45px;
    line-height: 45px;
    margin: 2px;
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .axolexQRcodeupload {
    height: 265px;
  }
  .nopaddingmailrow {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .Axolextopbtwidth {
    width: 118px;
  }
  .Axolextopbtwidth1 {
    width: 100px;
  }
}

.axo-icon {
  font-size: 16px;
  line-height: 1;
}

.iconxlSiza .axo-icon {
  font-size: 25px !important;
}

.axo-icon.form-control-feedback {
  font-size: 16px;
  line-height: 34px;
}

.justified-flag .dropup {
  width: 100%;
}

.panel-group .panel-heading {
  height: 41px;
}

.main-body {
  border-left: 1px ridge #e6e6e6;
}

.accounting .btn {
  background-image: linear-gradient(#fff, #fff);
  color: #1253a4 !important;
  border-bottom: 1px solid #1253a4;
  margin-bottom: -1px;
  font-weight: normal !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.accounting .btn:hover {
  background-image: linear-gradient(#1c4e80, #1c4e80);
  color: white !important;
  border-bottom: 1px solid #f96d00 !important;
  margin-bottom: -1px;
}

.accounting .btn.active {
  background-image: linear-gradient(#fff, #fff);
  border-bottom: 1px solid #f96d00 !important;
  margin-bottom: -1px;
  color: #009933;
}

@media (min-width: 0px) {
  .xs-center {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .sm-right {
    text-align: right;
  }
  .sm-left {
    text-align: left;
  }
}

.fullSize {
  width: 100%;
  height: 100%;
}

.flip {
  display: inline-block;
  transform: scale(-1, 1);
}

img.withLoader {
  display: block;
  background-image: url("../images/loading.gif");
  background-position: center center;
}

.bgTransparent {
  background: rgba(255, 255, 255, 0);
}

.noteText {
  color: #1253a4;
}

.inlineBlock {
  display: inline-block;
}

.rdtPickerOpenUpwards .rdtPicker {
  top: auto;
  bottom: 100%;
}

.standardMaxWidth {
  max-width: 990px;
}

.editorMaxWidth {
  max-width: 1090px;
}

.largeMaxWidth {
  max-width: 1200px;
}

.shortMaxWidth {
  max-width: 500px;
}

.leftPadding {
  padding-left: 15px;
}

.rightPadding {
  padding-right: 15px;
}

.topPadding {
  padding-top: 10px;
}

.bottomPadding {
  padding-bottom: 10px;
}

.bottomMargin {
  margin-bottom: 10px;
}

.panel-group .panel.bottomMargin {
  margin-bottom: 10px;
}

.onTop {
  position: relative;
  z-index: 9;
}

.onTopper {
  position: relative;
  z-index: 18;
}

.onToppest {
  position: relative;
  z-index: 100;
}

.onToppestGlobal {
  position: relative;
  z-index: 9999;
}

.selectOutline .Select-control {
  border: 1px solid black;
}

.axoTableText {
  font-size: 12px;
}

.fullHeight {
  height: 100%;
}

.selectBorder {
  border: 1px solid red;
  border-radius: 4px;
}

/* Fixes white border on top left header, next to the logo button */
.header-position .btn-group .btn + .btn,
.header-position .btn-group .btn + .btn-group,
.header-position .btn-group .btn-group + .btn,
.header-position .btn-group .btn-group + .btn-group {
  margin-left: 0px;
}

.hugeText {
  font-size: 28px;
}

.largeText {
  font-size: 18px;
}

.underline {
  text-decoration: underline;
}

.mediumText {
  font-size: 16px;
}

.smallText {
  font-size: 12px;
}

.paragraph {
  margin-bottom: 10px;
}

.printcenter {
  padding-left: 30%;
}

.employmentContractprint {
  padding-top: 10px !important;
  padding-left: 50px;
}

/* Button dropdown */
.buttonDropdown ul.dropdown-menu {
  min-width: 200px !important;
}

.buttonDropdown .dropdown-menu > div {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}

.buttonDropdown .dropdown-menu > div:first-child {
  padding-top: 10px;
}

.buttonDropdown .dropdown-menu .btn {
  width: 100%;
}

.dropDownExpand .dropdown {
  width: 100%;
}

.Axolexgul {
  color: #d18d2e;
}

.axoorange {
  color: #d18d2e;
}

.dropdown-yellow .css-bg1rzq-control {
  background-color: rgb(255, 255, 230) !important;
}

.Lex-button-2 {
  background-image: linear-gradient(#1a96cb, #177fad);
  color: #f2f2f2;
}
.Lex-button-2:hover {
  background-image: linear-gradient(#d28f33, #bc7f29);
  color: #f2f2f2;
}
.Lex-button-2.active {
  background-image: linear-gradient(#d28f33, #bc7f29);
  color: #ffffff;
}

.Lex-button-2.axonavTab {
  background-image: linear-gradient(#eaecec, #eaecec);
  color: #404040 !important;
  border-width: 0px 0px 1px 0px !important;
  border-color: #67abcb;
  font-weight: bold;
}
.Lex-button-2.axonavTab:hover {
  background-image: linear-gradient(#fff, #fff);
  color: #000000 !important;
}
.Lex-button-2.axonavTab.active {
  background-image: linear-gradient(#fff, #f2f2f2);
  color: #404040 !important;
  border-bottom: 4px solid #60a429 !important;
}
/* .Lex-button-2.axonavTab.btn-default {
  border-width: 0px 0px 1px 0px!important;
  border-color: #d7dbdb; } */

.axonavTab {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

/* axotopbannercolor i dashboard  */
.Axolexheader.btn-default {
  border: none;
}
.Axolexheader.btn-default:hover {
  background-image: linear-gradient(#fff, #fff);
  color: #404040 !important;
}

.Axolexheader {
  background-image: linear-gradient(#006399, #005380);
  color: #fff !important;
}
.Topheight.headerbuttonDropdown .dropdown button.dropdown-toggle {
  background-image: linear-gradient(#006399, #005380);
  color: #fff !important;
}
.Topheight.headerbuttonDropdown .dropdown button.dropdown-toggle:hover {
  background-image: linear-gradient(#fff, #fff);
  color: #404040 !important;
}
.AxoPriseElement1 {
  background-image: linear-gradient(#1a96cb, #1785b5);
  color: #fff !important;
  height: 66px;
  padding-left: 50px;
  display: flex;
}

/* Tab Color 1 sæt */

/* .Lex-button-2.axonavTab.axonavTabcolora {
      background-Image: linear-gradient(#ced0d2 , #ced0d2 );
      color: #fff; }
.Lex-button-2.axonavTab.axonavTabcolorb {
      background-Image: linear-gradient(#6aa3d5 , #6aa3d5 );
      color: #fff; }   
.Lex-button-2.axonavTab.axonavTabcolorc {
      background-Image: linear-gradient(#80c780 , #80c780 );
      color: #fff ; } 
.Lex-button-2.axonavTab.axonavTabcolord {
      background-Image: linear-gradient(#f4c37d, #f4c37d );
      color: #fff; }  
.Lex-button-2.axonavTab.axonavTabcolore {
      background-Image: linear-gradient(#e27c79, #e27c79 );
      color: #ffffff ; }  
.Lex-button-2.axonavTab.axonavTabcolorf {
      background-Image: linear-gradient(#fdc753, #fdc753 );
      color: #fff; }  
.Lex-button-2.axonavTab.axonavTabcolorg {
      background-Image: linear-gradient(#FFC497, #FFC497);
      color: #fff; } 
.Lex-button-2.axonavTab.axonavTabcolorh {
      background-Image: linear-gradient(#428bca, #428bca);
      color: #fff; }  
.Lex-button-2.axonavTab.axonavTabcolori {
      background-Image: linear-gradient(#c36e52, #c36e52);
      color: #fff; }  
.Lex-button-2.axonavTab.axonavTabcolorj {
      background-Image: linear-gradient(#88b4b8, #88b4b8);
      color: #fff; }  
.Lex-button-2.axonavTab.axonavTabcolork {
      background-Image: linear-gradient(#1a96cb, #1a96cb);
      color: #fff; }  
.Lex-button-2.axonavTab.axonavTabcolorl {
      background-Image: linear-gradient(#D28F33, #D28F33);
      color: #fff; }   */

/* .Lex-button-2.axonavTabcolora .axo-icon {
color: #79B0EC;
font-size:20px;} 
.Lex-button-2.axonavTabcolorb .axo-icon {
color:#EA7882;
font-size:20px;} 
.Lex-button-2.axonavTabcolorc .axo-icon {
color: #B4A1DD;
font-size:20px;} 

.Lex-button-2.axonavTabcolord .axo-icon {
color: #b1e7d5;
font-size:20px;}
.Lex-button-2.axonavTabcolore .axo-icon {
color: #edc1d3;
font-size:20px;}
.Lex-button-2.axonavTabcolorf .axo-icon {
color: #fdc753;
font-size:20px;}
.Lex-button-2.axonavTabcolorg .axo-icon {
  color: #FFC497;
font-size:20px;}
.Lex-button-2.axonavTabcolorh .axo-icon {
color: #428bca;
font-size:20px;}
.Lex-button-2.axonavTabcolori .axo-icon {
color: #c36e52;
font-size:20px;}
.Lex-button-2.axonavTabcolorj .axo-icon {
  color: #88b4b8;
font-size:20px;}
.Lex-button-2.axonavTabcolork .axo-icon {
color: #1a96cb;
font-size:20px;}
.Lex-button-2.axonavTabcolorl .axo-icon {
color: #D28F33;
font-size:20px;} */

.Lex-button-2.axonavTabcolora .axo-icon {
  color: #751a33;
  font-size: 20px;
}
.Lex-button-2.axonavTabcolorb .axo-icon {
  color: #b34233;
  font-size: 20px;
}
.Lex-button-2.axonavTabcolorc .axo-icon {
  color: #d28f33;
  font-size: 20px;
}
.Lex-button-2.axonavTabcolord .axo-icon {
  color: #d4b95e;
  font-size: 20px;
}
.Lex-button-2.axonavTabcolore .axo-icon {
  color: #4ea2a2;
  font-size: 20px;
}
.Lex-button-2.axonavTabcolorf .axo-icon {
  color: #1a8693;
  font-size: 20px;
}
.Lex-button-2.axonavTabcolorg .axo-icon {
  color: #0066cc;
  font-size: 20px;
}
.Lex-button-2.axonavTabcolorh .axo-icon {
  color: #4688f4;
  font-size: 20px;
}
.Lex-button-2.axonavTabcolori .axo-icon {
  color: #cc6600;
  font-size: 20px;
}
.Lex-button-2.axonavTabcolorj .axo-icon {
  color: #6600cc;
  font-size: 20px;
}
.Lex-button-2.axonavTabcolork .axo-icon {
  color: #cc00cc;
  font-size: 20px;
}
.Lex-button-2.axonavTabcolorl .axo-icon {
  color: #cc6600;
  font-size: 20px;
}

.Lex-button-2.axonavTabcolora.active .axo-icon {
  color: #404040 !important;
}
.Lex-button-2.axonavTabcolorb.active .axo-icon {
  color: #404040 !important;
}
.Lex-button-2.axonavTabcolorc.active .axo-icon {
  color: #404040 !important;
}
.Lex-button-2.axonavTabcolord.active .axo-icon {
  color: #404040 !important;
}
.Lex-button-2.axonavTabcolore.active .axo-icon {
  color: #404040 !important;
}
.Lex-button-2.axonavTabcolorf.active .axo-icon {
  color: #404040 !important;
}
.Lex-button-2.axonavTabcolorg.active .axo-icon {
  color: #404040 !important;
}
.Lex-button-2.axonavTabcolorh.active .axo-icon {
  color: #404040 !important;
}
.Lex-button-2.axonavTabcolori.active .axo-icon {
  color: #404040 !important;
}
.Lex-button-2.axonavTabcolorj.active .axo-icon {
  color: #404040 !important;
}
.Lex-button-2.axonavTabcolork.active .axo-icon {
  color: #404040 !important;
}
.Lex-button-2.axonavTabcolorl.active .axo-icon {
  color: #404040 !important;
}

.bgSignSide {
  background: #f7f7f7;
}

.bgSignCenter {
  background: #ffffff;
  border: 1px solid #bccccb;
}

/* Tab Color 2 sæt */
/* .Lex-button-2.axonavTab.axonavTabcolora {
      background-Image: linear-gradient(#79B0EC , #79B0EC );
      color: #fff; }
.Lex-button-2.axonavTab.axonavTabcolorb {
      background-Image: linear-gradient(#EA7882 , #EA7882 );
      color: #fff; }   
.Lex-button-2.axonavTab.axonavTabcolorc {
      background-Image: linear-gradient(#B4A1DD, #B4A1DD );
      color: #fff ; } 
.Lex-button-2.axonavTab.axonavTabcolord {
      background-Image: linear-gradient(#b1e7d5, #b1e7d5 );
      color: #fff; }  
.Lex-button-2.axonavTab.axonavTabcolore {
      background-Image: linear-gradient(#edc1d3, #edc1d3 );
      color: #fff ; }  
.Lex-button-2.axonavTab.axonavTabcolorf {
      background-Image: linear-gradient(#fdc753, #fdc753 );
      color: #fff ; }  
.Lex-button-2.axonavTab.axonavTabcolorg {
      background-Image: linear-gradient(#FFC497, #FFC497);
      color: #fff; } 
.Lex-button-2.axonavTab.axonavTabcolorh {
      background-Image: linear-gradient(#428bca, #428bca);
      color: #fff; }  
.Lex-button-2.axonavTab.axonavTabcolori {
      background-Image: linear-gradient(#c36e52, #c36e52);
      color: #fff; }  
.Lex-button-2.axonavTab.axonavTabcolorj {
      background-Image: linear-gradient(#88b4b8, #88b4b8);
      color: #fff; }  
.Lex-button-2.axonavTab.axonavTabcolork {
      background-Image: linear-gradient(#1a96cb, #1a96cb);
      color: #fff; }  
.Lex-button-2.axonavTab.axonavTabcolorl {
      background-Image: linear-gradient(#D28F33, #D28F33);
      color: #fff; }         */
.caseBox {
  background-image: linear-gradient(#eaecec, #eaecec);
  color: #404040;
  display: flex;
  align-items: center;
}
/* .Lex-button-2.axonavTab.axonavTabcolora {
        background-Image: linear-gradient(#eaecec , #eaecec );
        border-bottom: 11px solid #ced0d2!important;
        color: #404040!important; }
  .Lex-button-2.axonavTab.axonavTabcolorb {
        background-Image: linear-gradient(#eaecec , #eaecec );
        border-bottom: 11px solid #6aa3d5!important;
        color: #404040!important; }   
  .Lex-button-2.axonavTab.axonavTabcolorc {
        background-Image: linear-gradient(#eaecec , #eaecec );
        border-bottom: 11px solid #80c780!important;
        color: #404040 !important; } 
  .Lex-button-2.axonavTab.axonavTabcolord {
        background-Image: linear-gradient(#eaecec, #eaecec );
        border-bottom: 11px solid #f4c37d!important;
        color: #404040; }  
  .Lex-button-2.axonavTab.axonavTabcolore {
        background-Image: linear-gradient(#eaecec, #eaecec );
        border-bottom: 11px solid #e27c79!important;
        color: #404040 ; }  
  .Lex-button-2.axonavTab.axonavTabcolorf {
        background-Image: linear-gradient(#eaecec, #eaecec );
        border-bottom: 11px solid #fdc753!important;
        color: #404040; }  
  .Lex-button-2.axonavTab.axonavTabcolorg {
        background-Image: linear-gradient(#eaecec, #eaecec);
        border-bottom: 11px solid #FFC497!important;
        color: #404040; } 
  .Lex-button-2.axonavTab.axonavTabcolorh {
        background-Image: linear-gradient(#eaecec, #eaecec);
        border-bottom: 11px solid #428bca!important;
        color: #404040; }  
  .Lex-button-2.axonavTab.axonavTabcolori {
        background-Image: linear-gradient(#eaecec, #eaecec);
        border-bottom: 11px solid #c36e52!important;
        color: #404040; } 
        
        

        
  .Lex-button-2.axonavTab.axonavTabcolorj {
        background-Image: linear-gradient(#eaecec, #eaecec);
        border-bottom: 11px solid #88b4b8!important;
        color: #404040; }  
  .Lex-button-2.axonavTab.axonavTabcolork {
        background-Image: linear-gradient(#eaecec, #eaecec);
        border-bottom: 11px solid #1a96cb!important;
        color: #404040; }  
  .Lex-button-2.axonavTab.axonavTabcolorl {
        background-Image: linear-gradient(#eaecec, #eaecec);
        border-bottom: 11px solid #D28F33!important;
        color: #404040; } */

/*  Coloras icon
    .Lex-button-2.axonavTab.axonavTabcolora {
          border-right: 2px solid #751A33!important;
          border-left: 2px solid #751A33!important; }
    .Lex-button-2.axonavTab.axonavTabcolorb {
          border-right: 2px solid #B34233!important; }   
    .Lex-button-2.axonavTab.axonavTabcolorc {
          border-right: 2px solid #D28F33!important;} 
    .Lex-button-2.axonavTab.axonavTabcolord {
          border-right: 2px solid #D4B95E!important;}  
    .Lex-button-2.axonavTab.axonavTabcolore {
          border-right: 2px solid #4EA2A2!important;}  
    .Lex-button-2.axonavTab.axonavTabcolorf {
          border-right: 2px solid #1A8693!important;}  
    .Lex-button-2.axonavTab.axonavTabcolorg {
          border-right: 4px solid #0066cc!important; } 
    .Lex-button-2.axonavTab.axonavTabcolorh {
          border-right: 2px solid #4688F4!important;}  
    .Lex-button-2.axonavTab.axonavTabcolori {
          border-right: 2px solid #cc6600!important; }  
    .Lex-button-2.axonavTab.axonavTabcolorj {
          border-right: 2px solid #6600cc!important;}  
    .Lex-button-2.axonavTab.axonavTabcolork {
          border-right: 2px solid #cc00cc!important;}  
    .Lex-button-2.axonavTab.axonavTabcolorl {
          border-right: 2px solid #cc6600!important; } */
/* 
      .Lex-button-2.axonavTab.axonavTabcolora {
            border-right: 2px solid #006399!important;
            border-left: 2px solid #1a96cb!important; }
      .Lex-button-2.axonavTab.axonavTabcolorb {
            border-right: 2px solid #006399!important; }   
      .Lex-button-2.axonavTab.axonavTabcolorc {
            border-right: 2px solid #1a96cb!important;} 
      .Lex-button-2.axonavTab.axonavTabcolord {
            border-right: 2px solid #006399!important;}  
      .Lex-button-2.axonavTab.axonavTabcolore {
            border-right: 2px solid #1a96cb!important;}  
      .Lex-button-2.axonavTab.axonavTabcolorf {
            border-right: 2px solid #006399!important;}  
      .Lex-button-2.axonavTab.axonavTabcolorg {
            border-right: 4px solid #1a96cb!important; } 
      .Lex-button-2.axonavTab.axonavTabcolorh {
            border-right: 2px solid #006399!important;}  
      .Lex-button-2.axonavTab.axonavTabcolori {
            border-right: 2px solid #1a96cb!important; }  
      .Lex-button-2.axonavTab.axonavTabcolorj {
            border-right: 2px solid #006399!important;}  
      .Lex-button-2.axonavTab.axonavTabcolork {
            border-right: 2px solid #1a96cb!important;}  
      .Lex-button-2.axonavTab.axonavTabcolorl {
            border-right: 2px solid #006399!important; } 
 */

/* .Lex-button-2.axonavTab {
      font-weight: bold !important;
      font-size: 13px;
      line-height: 28px;
      }  */

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.fillHeight {
  height: 100%;
}

.bold {
  font-weight: bold;
}
