.language-da {
  /* line 6, ../sass/_content.sass */
  .offline-ui .offline-ui-retry:before {
    content: "Genopret";
  }
  /* line 11, ../sass/_content.sass */
  .offline-ui.offline-ui-up .offline-ui-content:before {
    content: "Computeren har forbindelse til internettet.";
  }
  @media (max-width: 1024px) {
    /* line 11, ../sass/_content.sass */
    .offline-ui.offline-ui-up .offline-ui-content:before {
      content: "Enheden har forbindelse til internettet.";
    }
  }
  @media (max-width: 568px) {
    /* line 11, ../sass/_content.sass */
    .offline-ui.offline-ui-up .offline-ui-content:before {
      content: "Enheden har netforbindelse.";
    }
  }
  /* line 22, ../sass/_content.sass */
  .offline-ui.offline-ui-down .offline-ui-content:before {
    content: "Computeren har mistet internetforbindelsen.";
  }
  @media (max-width: 1024px) {
    /* line 22, ../sass/_content.sass */
    .offline-ui.offline-ui-down .offline-ui-content:before {
      content: "Enheden har mistet internetforbindelsen.";
    }
  }
  @media (max-width: 568px) {
    /* line 22, ../sass/_content.sass */
    .offline-ui.offline-ui-down .offline-ui-content:before {
      content: "Enheden har ingen internetforbindelse.";
    }
  }
  /* line 33, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-connecting .offline-ui-content:before,
  .offline-ui.offline-ui-down.offline-ui-connecting-2s
    .offline-ui-content:before {
    content: "Forsøger at genoprette forbindelsen...";
  }
  /* line 42, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit="second"]:before {
    content: "Ingen netforbindelse. Genopretter om " attr(data-retry-in-value)
      " sekunder...";
  }
  @media (max-width: 568px) {
    /* line 42, ../sass/_content.sass */
    .offline-ui.offline-ui-down.offline-ui-waiting
      .offline-ui-content[data-retry-in-unit="second"]:before {
      content: "Genopretter om " attr(data-retry-in-value) "s...";
    }
  }
  /* line 50, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit="second"][data-retry-in-value="1"]:before {
    content: "Ingen netforbindelse. Genopretter om " attr(data-retry-in-value)
      " sekunder...";
  }
  @media (max-width: 568px) {
    /* line 50, ../sass/_content.sass */
    .offline-ui.offline-ui-down.offline-ui-waiting
      .offline-ui-content[data-retry-in-unit="second"][data-retry-in-value="1"]:before {
      content: "Genopretter om " attr(data-retry-in-value) "s...";
    }
  }
  /* line 58, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit="minute"]:before {
    content: "Ingen netforbindelse. Genopretter om " attr(data-retry-in-value)
      " minutter...";
  }
  @media (max-width: 568px) {
    /* line 58, ../sass/_content.sass */
    .offline-ui.offline-ui-down.offline-ui-waiting
      .offline-ui-content[data-retry-in-unit="minute"]:before {
      content: "Genopretter om " attr(data-retry-in-value) "m...";
    }
  }
  /* line 66, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit="minute"][data-retry-in-value="1"]:before {
    content: "Ingen netforbindelse. Genopretter om " attr(data-retry-in-value)
      " minutter...";
  }
  @media (max-width: 568px) {
    /* line 66, ../sass/_content.sass */
    .offline-ui.offline-ui-down.offline-ui-waiting
      .offline-ui-content[data-retry-in-unit="minute"][data-retry-in-value="1"]:before {
      content: "Genopretter om " attr(data-retry-in-value) "m...";
    }
  }
  /* line 74, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit="hour"]:before {
    content: "Ingen netforbindelse. Genopretter om " attr(data-retry-in-value)
      " timer...";
  }
  @media (max-width: 568px) {
    /* line 74, ../sass/_content.sass */
    .offline-ui.offline-ui-down.offline-ui-waiting
      .offline-ui-content[data-retry-in-unit="hour"]:before {
      content: "Genopretter om " attr(data-retry-in-value) "h...";
    }
  }
  /* line 82, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit="hour"][data-retry-in-value="1"]:before {
    content: "Ingen netforbindelse. Genopretter om " attr(data-retry-in-value)
      " timer...";
  }
  @media (max-width: 568px) {
    /* line 82, ../sass/_content.sass */
    .offline-ui.offline-ui-down.offline-ui-waiting
      .offline-ui-content[data-retry-in-unit="hour"][data-retry-in-value="1"]:before {
      content: "Genopretter " attr(data-retry-in-value) "h...";
    }
  }
  /* line 90, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s.offline-ui-waiting
    .offline-ui-retry {
    display: none;
  }
  /* line 93, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s
    .offline-ui-content:before {
    content: "Forbindelsen kunne ikke genoprettes.";
  }
}
