.theme-olive {
  .Lex-button-1 {
    background-image: linear-gradient(#ececec, #ececec);
    color: #000 !important;
  }
  .Lex-button-1:hover {
    background-image: linear-gradient(#912f1a, #912f1a);
    color: white !important;
  }
  .Lex-button-1.active {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f !important;
  }
  .axoFrontPage .Lex-button-1 {
    background-image: linear-gradient(#912f1a, #912f1a);
    color: white !important;
  }
  .axoFrontPage .Lex-button-1:hover {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f !important;
  }
  .axoFrontPage .Lex-button-1.active {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f !important;
  }

  .Lex-button-1xlink {
    background-image: linear-gradient(#912f1a, #912f1a);
    color: white !important;
  }
  .Lex-button-1xlink:hover {
    background-image: linear-gradient(#fffdfb, #fffdfb);
    color: #062f4f !important;
  }
  .Lex-button-1xlink.active {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f !important;
  }

  .text_wrapper_no_hover {
    @media screen and (max-width: 640px) {
      background: linear-gradient(to right, #912f1a, #912f1a) !important;
      border-bottom: 1px solid #062f4f !important;
      margin-bottom: -1px;
    }
  }

  .text_wrapper {
    @media screen and (max-width: 640px) {
      background: linear-gradient(to right, #912f1a, #912f1a) !important;
      border-bottom: 1px solid #062f4f !important;
      margin-bottom: -1px;
    }
  }

  //0881A3
  .Lex-button-1.axoSidebarbutton {
    background-image: linear-gradient(#ececec, #ececec);
    color: #912f1a !important;
  }
  .Lex-button-1.axoSidebarbutton:hover {
    background-image: linear-gradient(#fffdfb, #fffdfb);
    color: #062f4f !important;
  }
  .Lex-button-1.axoSidebarbutton.active {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f !important;
  }
  .Lex-button-1.axoSidebarbutton:visited {
    background-image: linear-gradient(#fff, #fff);
    color: #912f1a !important;
  }

  .Lex-Accordion .panel-heading {
    background-image: linear-gradient(#ececec, #ececec) !important;
    color: #912f1a !important;
  }

  .Lex-Accordion .panel-heading a {
    color: #912f1a !important;
  }

  .Lex-Accordion .panel-heading:hover {
    background-image: linear-gradient(#912f1a, #912f1a) !important;
    color: #fff !important;
  }

  .Lex-Accordion .panel-heading:hover a {
    color: #fff !important;
  }

  .Lex-Accordion .panel-heading.active {
    background-image: linear-gradient(#fff, #fff) !important;
    color: #912f1a !important;
  }

  .Lex-Accordion .panel-heading a.active {
    color: #912f1a !important;
  }

  .Lex-Accordion.LexAccordionsidebar .panel {
    border: 0px !important;
  }

  .Chfontcssbanne.Axobold {
    color: #062f4f !important;
  }
  .Lex-DropdownButton-menu {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f;
  }
  .dropdown-menu li.Lex-DropdownButton-menu > a {
    border-color: white;
    color: #062f4f;
  }
  .dropdown-menu li.Lex-DropdownButton-menu > a:hover {
    border-color: white;
    color: #1253a4;
    border-bottom: 1px solid #1253a4;
    margin-bottom: -1px;
  }

  .Lex-DropdownButton-menu:hover {
    background-image: linear-gradient(#fffdfb, #fffdfb);
    color: #1253a4 !important;
  }
  .Lex-DropdownButton-menu.active {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f !important;
  }

  .Lex-DropdownButton-menuX {
    background-image: linear-gradient(#912f1a, #912f1a);
    color: white;
  }
  .Lex-DropdownButton-menuX:hover {
    background-image: linear-gradient(#fffdfb, #fffdfb);
    color: #1253a4 !important;
  }
  .dropdown-menu li.Lex-DropdownButton-menuX > a {
    border-color: white;
    color: white !important;
  }
  .Lex-DropdownButton-menuX.active {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f !important;
  }

  .axotpprice {
    background-color: #fffdfb;
    color: #062f4f;
  }
  .axopointer {
    background: url("../../resources/svg/axoweather3small.jpg") center;
  }
  .axoFrontPage {
    h3,
    h4 {
      color: #062f4f;
    }
  }

  .frontPageaxolexdivtextbox a {
    color: #062f4f !important;
  }

  .axoFrontPage .carousel-caption {
    color: #062f4f !important;
  }
  .image-banner .text_wrapper_no_hover {
    border-bottom: 5px solid #062f4f;
  }

  .axolexwebsideicon {
    color: #062f4f;
  }
  .axolexwebsideicon a:hover {
    color: #202020 !important ;
  }
  .axolexwebsideicon a {
    color: #062f4f !important ;
  }
  .axolexwebsideicon a:active {
    color: #062f4f !important ;
  }
  .axoFrontPage .input-group-addon {
    color: #062f4f;
    background-color: #ececec;
  }

  input[type="text"]:disabled {
    background: #fffdfb;
  }

  .Accordionbackgroundmailmenu {
    .panel-heading {
      background-image: linear-gradient(#fff, #fff) !important;
      color: #062f4f !important;
    }
  }
  .Accordionbackgroundmailmenu {
    .panel-heading:hover {
      background-image: linear-gradient(#fffdfb, #fffdfb) !important;
      color: #202020 !important;
    }
    a:hover {
      color: #202020 !important ;
    }
  }
  .Accordionbackgroundmailmenu {
    .panel-heading.active {
      background-image: linear-gradient(#fff, #fff) !important;
      color: #062f4f !important;
    }
    a:active {
      color: #062f4f !important ;
    }
  }

  // .Lex-Accordion {
  //   .panel-heading {
  //   background-Image: linear-gradient(#912F1A, #912F1A) !important;
  //   color:white !important;
  //   border-bottom: 1px solid transparent !important;
  //   margin-bottom: -1px;
  //     }
  //     }
  // .Lex-Accordion {
  //     .panel-heading:hover {
  //     background-Image: linear-gradient(#FFFDFB, #FFFDFB)!important;
  //     color:#062f4f !important;
  //     border-bottom: 1px solid #062f4f !important;
  //     margin-bottom: -1px;}
  //     a:hover {
  //     color:#062f4f !important ;
  //     }
  //     }
  // .Lex-Accordion {
  //     .panel-heading.active {
  //     background-Image: linear-gradient(#fff, #fff) !important;
  //     color:#062f4f !important;
  //     border-bottom: 1px solid #062f4f !important;
  //     margin-bottom: -1px;
  //       }
  //     a:active {
  //     color:#062f4f !important ;
  //     }
  //     }

  .Lex-Accordion.caseeditinline {
    .panel-heading {
      background-image: linear-gradient(#ececec, #ececec) !important;
      color: #1253a4 !important ;
      border-bottom: 1px solid white !important;
      margin-bottom: -1px;
    }
  }
  .Lex-Accordion.caseeditinline:hover {
    .panel-heading {
      background-image: linear-gradient(#fff, #fff) !important;
      color: #4caf50 !important ;
      border-bottom: 1px solid #4caf50 !important;
      margin-bottom: -1px;
    }
  }
  .Lex-Accordion.caseeditinline.active {
    .panel-heading {
      background-image: linear-gradient(#fff, #ececec) !important;
      color: #4caf50 !important ;
      border-bottom: 1px solid #4caf50 !important;
      margin-bottom: -1px;
    }
  }
  .dropdown-menu li > a {
    color: #062f4f !important;
  }
  .dropdown-menu li > a:hover {
    color: #912f1a !important;
  }
  .axobg {
    background-color: white;
    color: #912f1a;
  }

  button.btn.Lex-button {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f !important;
    font-size: 12px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  button.btn.Lex-button:hover {
    background-image: linear-gradient(#912f1a, #912f1a);
    color: white !important;
  }
  button.btn.Lex-button.active {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f;
  }
  .accounting .btn {
    background-image: linear-gradient(#fff, #fff);
    color: #1253a4 !important;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .accounting .btn:hover {
    background-image: linear-gradient(#912f1a, #912f1a);
    color: white !important;
  }
  .accounting .btn.active {
    background-image: linear-gradient(#fff, #fff);
    color: #009933;
  }

  .Lex-CardFooter {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f !important;
  }

  .axogrouplist > div:nth-child(even) {
    background-image: linear-gradient(#fff, #fff);
    color: #912f1a !important ;
  }
  .axogrouplist > div:nth-child(odd) {
    background-image: linear-gradient(#f9f9f9, #f9f9f9);
    color: #912f1a !important ;
  }
  .axogrouplist > div:hover {
    background-image: linear-gradient(#fffdfb, #fffdfb);
    color: #062f4f !important ;
    border-bottom: 1px solid #062f4f !important;
  }
  .axogrouplist div.active {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f !important ;
    border-bottom: 1px solid #062f4f !important;
  }

  .TimeButtoncolor {
    background-image: linear-gradient(#912f1a, #912f1a);
    color: white !important ;
  }
  .Bannecolor {
    background-image: linear-gradient(#fff2e7, #fff2e7) !important;
    color: #062f4f !important ;
  }
  .frontPageaxolexdiv.Bannecolor {
    border-bottom: 1px solid #c0a692 !important;
    margin-bottom: -1px;
  }

  .Axoaccountsbannebg {
    background-image: linear-gradient(#fff, #fff);
    color: #1253a4;
    border-bottom: 1px solid #1253a4;
  }

  .axolextopmenuButton {
    background-image: linear-gradient(#ececec, #ececec);
    color: #912f1a !important ;
    border: 0px !important ;
  }
  .axolextopmenuButton:hover {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f !important ;
  }
  .dropdown button.axolextopmenuButton.noboder.Axolextopbtwidth {
    background-image: linear-gradient(#ececec, #ececec);
    color: #912f1a !important ;
  }
  .dropdown button.axolextopmenuButton.noboder.Axolextopbtwidth:hover {
    background-image: linear-gradient(#fff, #fff);
    color: #062f4f !important ;
  }

  //Modal
  .backgroundModalHeaderFooter {
    background-image: linear-gradient(#fffdfb, #fffdfb) !important;
    color: #202020 !important ;
  }

  // <weathertext>
  .axoweathertext {
    color: #062f4f !important;
    font-weight: normal;
  }
  .icon.axoweathertext img {
    border-bottom: 1px solid #202020 !important;
    margin-bottom: -1px;
  }
  .blurred-background.axoweathertextbg {
    background-image: url("../../resources/svg/linktopA.jpg") !important;
    background-repeat: no-repeat !important;
  }
  //</weathertext>

  .Lex-Accordion.IndividualPackageDetal .panel-heading {
    background-image: linear-gradient(#f8f8f8, #ececec) !important;
    color: #912f1a !important;
  }
  .Lex-Accordion.IndividualPackageDetal .panel-heading:hover {
    background-image: linear-gradient(#fff, #fff) !important;
    color: #4caf50 !important;
  }

  // alm banne bg
  .axolextextbanne {
    background-image: linear-gradient(#fde9df, #fde9df) !important;
    color: #062f4f !important;
  }

  .editable {
    color: #1253a4;
  }
  //<dropdown mailmapper>

  .Lex-button-1:hover.btn-link.lex-dropdown {
    color: #c0a692 !important;
    font-weight: normal;
  }
  .btn-link.lex-dropdown {
    color: white !important;
    font-weight: normal;
  }
  .btn-link.lex-dropdown:hover {
    background-image: linear-gradient(#fffdfb, #fffdfb);
    color: #062f4f !important;
    font-weight: normal;
  }

  .slick-next:before,
  .slick-prev:before {
    color: #062f4f !important;
    font-size: 40px !important;
  }

  .rbc-today {
    background-color: #fff2e7;
  }

  // netværk
  .border-green {
    border-color: #062f4f !important;
  }
  .Axo-button-1 {
    background-image: linear-gradient(#e6e6e6, #e6e6e6);
    color: #4caf50;
  }
  .Axo-button-1:hover {
    background-image: linear-gradient(#4caf50, #4caf50);
    color: #fff !important;
  }
  .Axo-button-1.active {
    background-image: linear-gradient(#4caf50, #4caf50) !important;
    color: #fff !important;
  }

  .dropzone.our-dropzone {
    background: #fff2e7;
    color: #202020 !important;
  }
  .dz-message.our-dz-message {
    color: #202020;
  }

  .axotimeline::after {
    background-color: #062f4f;
  }
  .timelinecontainer::after {
    background-color: #912f1a;
    border: 2px solid white;
  }

  .btnSidebarSelect {
    border-right: 1px solid white;
    background-image: linear-gradient(#912f1a, #912f1a);
    color: white !important ;
  }
  .btnSidebarSelect:hover {
    border-right: 1px solid white;
    background-image: linear-gradient(#fff, #fff);
    color: #912f1a !important ;
  }
  .btnSidebarSelect:active {
    border-right: 1px solid white;
    background-image: linear-gradient(#912f1a, #912f1a);
    color: white !important ;
  }
}
