.container {
  margin-top: 15px;
}

.current-forecast {
  color: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
}

.current-forecast.list-group {
  margin-bottom: 0;
}

.current-forecast .list-group-item {
  border: none;
  background: transparent;
}

.city {
  font-weight: normal;
  font-size: 1.5em;
}

.date {
  font-size: 85%;
}

.icon,
.temp,
.conditions {
  text-align: center;
}

.icon img {
  border: 1px solid white;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  padding: 10px;
}

.temp {
  font-size: 3em;
}

.conditions {
  font-size: 2em;
}

.future-forecast {
  background: #f5f5f5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.future-forecast .list-group-item:nth-child(2n) {
  background: #eaecec;
}
