.language-enUS {
  font-weight: normal !important;
  color: #202020;

  .hidden-englishUS {
    display: none !important;
  }

  .visible-englishUS {
    display: block !important;
  }
}
