.react-contextmenu {
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 12px;
  color: #373a3c;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  outline: none;
  opacity: 0;
  pointer-events: none;
  z-index: 100;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
}

.react-contextmenu-item {
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item:hover {
  color: #fff;
  background-color: #20a0ff;
  border-color: #e6e6e6;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  color: #878a8c;
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item--divider {
  margin-bottom: 3px;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: -1px;
  cursor: inherit;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}
