.flexbox {
  display: flex;
}

.spaceBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
}

.alignCenter {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.justified > div {
  flex: 1;
}

.justified > button {
  flex: 1;
}

.flexbox.responsive {
  flex-wrap: wrap;
}

.flexbox-standard {
  display: flex;
  align-items: center;
}

.flexbox-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexbox-center-vertical {
  display: flex;
  align-items: center;
}

.verticalCenter {
  display: flex;
  align-items: center;
}

.flexbox-center-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.flexbox-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flexbox-responsive {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.flexbox-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flexbox-justified {
  display: flex;
  align-items: center;
}

.flexbox-column {
  display: flex;
  flex-direction: column;
}

.flexbox-justified > div {
  flex: 1;
}

.flexbox-justified > button {
  flex: 1;
}

@media (max-width: 767px) {
  .collapse-xs {
    display: block;
  }
}

@media (max-width: 991px) {
  .collapse-sm {
    display: block;
  }
}

@media (max-width: 1199px) {
  .collapse-md {
    display: block;
  }
}
