.highlight-row .public_fixedDataTableCell_main {
  background: lightblue;
}

.highlight-row-approved .public_fixedDataTableCell_main {
  background: lightgreen;
}

.highlight-row-rejected .public_fixedDataTableCell_main {
  background: red;
}

.highlight-row-warning .public_fixedDataTableCell_main {
  background: orange;
}

.highlight-row-creditor .public_fixedDataTableCell_main {
  background: darkorange;
}

.highlight-row-debitor .public_fixedDataTableCell_main {
  background: mediumseagreen;
}

.highlight-row-0 .public_fixedDataTableCell_main {
  background: LightCoral;
}
.highlight-row-1 .public_fixedDataTableCell_main {
  background: gray;
}
.highlight-row-2 .public_fixedDataTableCell_main {
  background: yellow;
}
.highlight-row-3 .public_fixedDataTableCell_main {
  background: Linen;
}
.highlight-row-4 .public_fixedDataTableCell_main {
  background: PaleGreen;
}
.highlight-row-5 .public_fixedDataTableCell_main {
  background: aqua;
}
.highlight-row-6 .public_fixedDataTableCell_main {
  background: teal;
}
.highlight-row-7 .public_fixedDataTableCell_main {
  background: white;
}
.highlight-row-8 .public_fixedDataTableCell_main {
  background: Pink;
}
.highlight-row-9 .public_fixedDataTableCell_main {
  background: lime;
}
.highlight-row-10 .public_fixedDataTableCell_main {
  background: lightblue;
}
.highlight-row-11 .public_fixedDataTableCell_main {
  background: Turquoise;
}
.highlight-row-12 .public_fixedDataTableCell_main {
  background: DeepSkyBlue;
}
.highlight-row-13 .public_fixedDataTableCell_main {
  background: AliceBlue;
}
.highlight-row-14 .public_fixedDataTableCell_main {
  background: silver;
}

.public_fixedDataTableCell_cellContent {
  padding: 2px 2px 2px 2px !important;
}
