/* line 4, ../sass/_offline-theme-base.sass */
.offline-ui,
.offline-ui *,
.offline-ui:before,
.offline-ui:after,
.offline-ui *:before,
.offline-ui *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 7, ../sass/_offline-theme-base.sass */
.offline-ui {
  display: none;
  position: fixed;
  background: white;
  z-index: 2000;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
}
/* line 17, ../sass/_offline-theme-base.sass */
.offline-ui .offline-ui-content:before {
  display: inline;
}
/* line 20, ../sass/_offline-theme-base.sass */
.offline-ui .offline-ui-retry {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: none;
}
/* line 24, ../sass/_offline-theme-base.sass */
.offline-ui .offline-ui-retry:before {
  display: inline;
}
/* line 29, ../sass/_offline-theme-base.sass */
.offline-ui.offline-ui-up.offline-ui-up-5s {
  display: block;
}
/* line 32, ../sass/_offline-theme-base.sass */
.offline-ui.offline-ui-down {
  display: block;
}
/* line 37, ../sass/_offline-theme-base.sass */
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-retry {
  display: block;
}
/* line 42, ../sass/_offline-theme-base.sass */
.offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s.offline-ui-waiting
  .offline-ui-retry {
  display: none;
}

@-webkit-keyframes offline-dropin {
  /* line 40, ../sass/_keyframes.sass */
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 0;
  }

  /* line 43, ../sass/_keyframes.sass */
  1% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
    opacity: 0;
  }

  /* line 48, ../sass/_keyframes.sass */
  2% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
    opacity: 1;
  }

  /* line 51, ../sass/_keyframes.sass */
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
  }
}

@-moz-keyframes offline-dropin {
  /* line 40, ../sass/_keyframes.sass */
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 0;
  }

  /* line 43, ../sass/_keyframes.sass */
  1% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
    opacity: 0;
  }

  /* line 48, ../sass/_keyframes.sass */
  2% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
    opacity: 1;
  }

  /* line 51, ../sass/_keyframes.sass */
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
  }
}

@-ms-keyframes offline-dropin {
  /* line 40, ../sass/_keyframes.sass */
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 0;
  }

  /* line 43, ../sass/_keyframes.sass */
  1% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
    opacity: 0;
  }

  /* line 48, ../sass/_keyframes.sass */
  2% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
    opacity: 1;
  }

  /* line 51, ../sass/_keyframes.sass */
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
  }
}

@-o-keyframes offline-dropin {
  /* line 40, ../sass/_keyframes.sass */
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 0;
  }

  /* line 43, ../sass/_keyframes.sass */
  1% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
    opacity: 0;
  }

  /* line 48, ../sass/_keyframes.sass */
  2% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
    opacity: 1;
  }

  /* line 51, ../sass/_keyframes.sass */
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes offline-dropin {
  /* line 40, ../sass/_keyframes.sass */
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 0;
  }

  /* line 43, ../sass/_keyframes.sass */
  1% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
    opacity: 0;
  }

  /* line 48, ../sass/_keyframes.sass */
  2% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
    opacity: 1;
  }

  /* line 51, ../sass/_keyframes.sass */
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes offline-dropout {
  /* line 57, ../sass/_keyframes.sass */
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 59, ../sass/_keyframes.sass */
  100% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
  }
}

@-moz-keyframes offline-dropout {
  /* line 57, ../sass/_keyframes.sass */
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 59, ../sass/_keyframes.sass */
  100% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
  }
}

@-ms-keyframes offline-dropout {
  /* line 57, ../sass/_keyframes.sass */
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 59, ../sass/_keyframes.sass */
  100% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
  }
}

@-o-keyframes offline-dropout {
  /* line 57, ../sass/_keyframes.sass */
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 59, ../sass/_keyframes.sass */
  100% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
  }
}

@keyframes offline-dropout {
  /* line 57, ../sass/_keyframes.sass */
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 59, ../sass/_keyframes.sass */
  100% {
    transform: translateY(-800px);
    -webkit-transform: translateY(-800px);
    -moz-transform: translateY(-800px);
    -ms-transform: translateY(-800px);
    -o-transform: translateY(-800px);
  }
}

@-webkit-keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

@-moz-keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

@-ms-keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

@-o-keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

@keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

/* line 16, ../sass/offline-theme-chrome.sass */
.offline-ui {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 0 1em rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 0 1em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 0 1em rgba(0, 0, 0, 0.3);
  font-family: "Lucida Grande", sans-serif;
  font-size: 14px;
  padding: 1em;
  width: 38em;
  max-width: 100%;
  background: #f6f6f6;
  color: #444444;
  overflow: hidden;
}
/* line 27, ../sass/offline-theme-chrome.sass */
.offline-ui .offline-ui-content {
  padding-left: 2em;
}
/* line 30, ../sass/offline-theme-chrome.sass */
.offline-ui .offline-ui-content:before {
  line-height: 1.25em;
}
/* line 33, ../sass/offline-theme-chrome.sass */
.offline-ui .offline-ui-content:after {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1em;
  margin: auto;
  height: 1em;
  width: 1em;
}
/* line 45, ../sass/offline-theme-chrome.sass */
.offline-ui .offline-ui-retry {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08),
    inset 0 1px 2px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08),
    inset 0 1px 2px rgba(255, 255, 255, 0.75);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08),
    inset 0 1px 2px rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, #ededed),
    color-stop(38%, #ededed),
    color-stop(100%, #dedede)
  );
  background-image: -webkit-linear-gradient(#ededed, #ededed 38%, #dedede);
  background-image: -moz-linear-gradient(#ededed, #ededed 38%, #dedede);
  background-image: -o-linear-gradient(#ededed, #ededed 38%, #dedede);
  background-image: linear-gradient(#ededed, #ededed 38%, #dedede);
  position: absolute;
  right: 4em;
  top: 1em;
  bottom: 1em;
  border: 1px solid rgba(0, 0, 0, 0.25);
  text-shadow: 0 1px 0 #f0f0f0;
  padding: 0 1em;
  line-height: 1.6em;
  height: 1.7em;
  margin: auto;
  font-size: 12px;
  text-decoration: none;
  color: inherit;
}
/* line 63, ../sass/offline-theme-chrome.sass */
.offline-ui.offline-ui-up {
  -webkit-animation: offline-dropout forwards 0.5s 2s;
  -moz-animation: offline-dropout forwards 0.5s 2s;
  -ms-animation: offline-dropout forwards 0.5s 2s;
  -o-animation: offline-dropout forwards 0.5s 2s;
  animation: offline-dropout forwards 0.5s 2s;
  -webkit-backface-visibility: hidden;
}
/* line 66, ../sass/offline-theme-chrome.sass */
.offline-ui.offline-ui-up .offline-ui-content:after {
  background: #80d580;
}
/* line 69, ../sass/offline-theme-chrome.sass */
.offline-ui.offline-ui-down {
  -webkit-animation: offline-dropin 0.5s;
  -moz-animation: offline-dropin 0.5s;
  -ms-animation: offline-dropin 0.5s;
  -o-animation: offline-dropin 0.5s;
  animation: offline-dropin 0.5s;
  -webkit-backface-visibility: hidden;
}
/* line 72, ../sass/offline-theme-chrome.sass */
.offline-ui.offline-ui-down .offline-ui-content:after {
  background: #ec8787;
}
/* line 75, ../sass/offline-theme-chrome.sass */
.offline-ui.offline-ui-down.offline-ui-connecting,
.offline-ui.offline-ui-down.offline-ui-waiting {
  padding-right: 3em;
}
/* line 78, ../sass/offline-theme-chrome.sass */
.offline-ui.offline-ui-down.offline-ui-connecting .offline-ui-content:after,
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content:after {
  background: #ec8787;
}
/* line 81, ../sass/offline-theme-chrome.sass */
.offline-ui.offline-ui-down.offline-ui-connecting:after,
.offline-ui.offline-ui-down.offline-ui-waiting:after {
  -webkit-animation: offline-rotation 0.7s linear infinite;
  -moz-animation: offline-rotation 0.7s linear infinite;
  -ms-animation: offline-rotation 0.7s linear infinite;
  -o-animation: offline-rotation 0.7s linear infinite;
  animation: offline-rotation 0.7s linear infinite;
  -webkit-backface-visibility: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  content: " ";
  display: block;
  position: absolute;
  right: 1em;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 1em;
  width: 1em;
  border: 2px solid rgba(0, 0, 0, 0);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-left-color: rgba(0, 0, 0, 0.5);
  opacity: 0.7;
}
/* line 98, ../sass/offline-theme-chrome.sass */
.offline-ui.offline-ui-down.offline-ui-waiting {
  padding-right: 11em;
}
/* line 101, ../sass/offline-theme-chrome.sass */
.offline-ui.offline-ui-down.offline-ui-waiting.offline-ui-reconnect-failed-2s {
  padding-right: 0;
}
