.language-ch {
  b,
  th,
  .btn {
    font-weight: normal;
  }

  body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif, "宋体", SimSun,
      "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑" !important;
  }
  .chbannetaxt {
    div,
    span,
    select,
    option {
      color: white;
      font-weight: normal;
    }
  }

  td.Chfontcssbanne {
    div,
    span,
    select,
    option {
      font-weight: bold !important;
    }
  }

  .FrontPageindextry span {
    font-weight: normal !important;
    color: white !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif, "宋体", SimSun,
      "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑" !important;
  }

  div,
  span,
  select,
  option {
    font-weight: normal;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif, "宋体", SimSun,
      "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑" !important;
  }

  div.Axolextextbox {
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif, "宋体", SimSun,
      "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑" !important;
    font-size: 15px;
    text-align: left;
    letter-spacing: 0em;
    display: block;
    text-indent: 50px;
    text-align: justify;
    text-justify: inter-word;
    letter-spacing: 1px;
    color: #202020;
    line-height: 1.8em;
    margin-bottom: 0.8em;
  }
  .FontPageViewNavButton {
    font-weight: normal !important;
    color: #202020;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif, "宋体", SimSun,
      "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑" !important;
  }

  div.FontPageViewNavButton {
    font-weight: normal !important;
    color: #202020;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif, "宋体", SimSun,
      "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑" !important;
  }

  .hidden-chinese {
    display: none !important;
  }

  .visible-chinese {
    display: block !important;
  }
}
