.theme-redaxo15 {
  .Lex-button-1 {
    background-image: linear-gradient(#177fad, #177fad);
    color: #ffffff !important;
  }
  .Lex-button-1:hover {
    background-image: linear-gradient(#d28f33, #d28f33);
    color: #ffffff !important;
  }
  .Lex-button-1.active {
    background: rgba(41, 160, 245, 0.129);
    color: #005380 !important;
  }

  .tabBannerRow {
    background-color: #eaecec;
    max-height: 38px;
    border-bottom: 1px solid #67abcb;
  }

  .axo-nav-lg .showListButton {
    display: none;
  }

  .axo-nav-lg .btn-group-justified > .btn-group.showListButton {
    display: none;
  }

  .Lex-button-1.axonavTab {
    background-image: linear-gradient(#f1f3f4, #f1f3f4) !important;
    color: #005380 !important;
    height: 35px;
    border-bottom: 1px solid #005380;
    margin-bottom: -1px;
  }
  .Lex-button-1.axonavTab:hover {
    background-image: linear-gradient(#fff, #fff) !important;
    border-bottom: 0px;
  }
  .Lex-button-1.axonavTab.active {
    background-image: linear-gradient(#fff, #fff) !important;
    color: #404040 !important;
    border-left: 1px solid #67abcb;
    border-right: 1px solid #67abcb;
    border-bottom: 0px solid;
    margin-right: -1px !important;
    margin-left: -1px !important;
  }
  .Tabbanne .dropdown button {
    background: #67abcb;
    color: #1c4e80;
    max-height: 30px !important;
    padding-top: 0px;
  }
  .dropdown button:hover {
    background: #eaecec;
    color: #4caf50;
  }

  .axoFrontPage .Lex-button-1 {
    background-image: linear-gradient(#005380, #005380);
    color: white !important;
  }
  .axoFrontPage .Lex-button-1:hover {
    background-image: linear-gradient(#fff, #fff);
    color: #005380 !important;
  }
  .axoFrontPage .Lex-button-1.active {
    background-image: rgba(41, 160, 245, 0.129);
    color: #005380 !important;
  }

  .Lex-button-1xlink {
    background-image: linear-gradient(#005380, #005380);
    color: white !important;
  }
  .Lex-button-1xlink:hover {
    background-image: linear-gradient(#eaecec, #eaecec);
    color: #005380 !important;
  }
  .Lex-button-1xlink.active {
    background-image: linear-gradient(
      rgba(41, 160, 245, 0.129),
      rgba(41, 160, 245, 0.129)
    );
    color: #005380 !important;
  }

  .text_wrapper_no_hover {
    @media screen and (max-width: 640px) {
      background: linear-gradient(to right, #005380, #005380) !important;
      border-bottom: 1px solid #005380 !important;
      margin-bottom: -1px;
    }
  }

  .text_wrapper {
    @media screen and (max-width: 640px) {
      background: linear-gradient(to right, #005380, #005380) !important;
      border-bottom: 1px solid #005380 !important;
      margin-bottom: -1px;
    }
  }

  //0881A3
  .Lex-button-1.axoSidebarbutton {
    background-image: linear-gradient(#eaecec, #eaecec);
    color: #404040 !important;
    font-size: 15px;
  }
  .Lex-button-1.axoSidebarbutton:hover {
    background-image: linear-gradient(#fff, #fff) !important;
    color: #005380 !important;
  }
  .Lex-button-1.axoSidebarbutton.active {
    background-image: linear-gradient(#fff, #fff) !important;
    color: #005380 !important;
  }
  .Lex-button-1.axoSidebarbutton:visited {
    background-image: linear-gradient(#dcedff, #dcedff);
    color: #005380 !important;
  }

  .Lex-Accordion .panel-heading {
    background-image: linear-gradient(#f2f2f2, #e6e6e6) !important;
    color: #005380 !important;
  }
  .Lex-Accordion .panel-heading a {
    color: #404040 !important;
    font-size: 15px;
  }
  .Lex-Accordion .panel-heading a:hover {
    color: #005380 !important;
  }

  .Lex-Accordion .panel-heading:hover {
    background-image: linear-gradient(
      rgba(41, 160, 245, 0.129),
      rgba(41, 160, 245, 0.129)
    ) !important;
    color: #005380 !important;
  }

  .Lex-Accordion .panel-heading.active {
    background-image: linear-gradient(
      rgba(41, 160, 245, 0.129),
      rgba(41, 160, 245, 0.129)
    );
    color: #005380 !important;
  }

  .Lex-Accordion .panel-heading a.active {
    color: #005380 !important;
  }

  .Chfontcssbanne.Axobold {
    color: #005380 !important;
  }
  .Lex-DropdownButton-menu {
    background-image: linear-gradient(#fff, #fff);
    color: #005380;
  }
  .dropdown-menu li.Lex-DropdownButton-menu > a {
    border-color: white;
    color: #404040;
  }
  .dropdown-menu li.Lex-DropdownButton-menu > a:hover {
    border-color: white;
    color: #fff;
    border-bottom: 1px solid #005380;
    margin-bottom: -1px;
  }
  .dropdown-menu li.Lex-DropdownButton-menu.active > a {
    color: #fff !important;
    border-bottom: 1px solid #005380;
    margin-bottom: -1px;
  }

  .dropdown-menu li > a {
    color: #404040 !important;
  }
  .dropdown-menu li > a:hover {
    color: #005380 !important;
  }

  .Lex-DropdownButton-menu:hover {
    background-image: linear-gradient(#eaecec, #eaecec);
    color: #005380 !important;
  }
  .Lex-DropdownButton-menu.active {
    background-image: linear-gradient(
      rgba(41, 160, 245, 0.129),
      rgba(41, 160, 245, 0.129)
    );
    color: #005380 !important;
  }

  .Lex-DropdownButton-menuX {
    background-image: linear-gradient(#005380, #005380);
    color: white;
  }
  .Lex-DropdownButton-menuX:hover {
    background-image: linear-gradient(#eaecec, #eaecec);
    color: #005380 !important;
  }
  .dropdown-menu li.Lex-DropdownButton-menuX > a {
    border-color: white;
    color: white !important;
  }
  .Lex-DropdownButton-menuX.active {
    background-image: linear-gradient(
      rgba(41, 160, 245, 0.129),
      rgba(41, 160, 245, 0.129)
    );
    color: #005380 !important;
  }

  .axotpprice {
    background-color: #eaecec;
    color: #005380;
  }
  .axopointer {
    background: url("../../resources/svg/axoweather3small.jpg") center;
  }
  .axoFrontPage {
    h3,
    h4 {
      color: #005380;
    }
  }

  .frontPageaxolexdivtextbox a {
    color: #005380 !important;
  }

  .axoFrontPage .carousel-caption {
    color: #005380 !important;
  }
  .image-banner .text_wrapper_no_hover {
    border-bottom: 5px solid #005380;
  }

  .axolexwebsideicon {
    color: #005380;
  }
  .axolexwebsideicon a:hover {
    color: #202020 !important ;
  }
  .axolexwebsideicon a {
    color: #005380 !important ;
  }
  .axolexwebsideicon a:active {
    color: #005380 !important ;
  }
  .axoFrontPage .input-group-addon {
    color: #005380;
    background-color: #eaecec;
  }

  input[type="text"]:disabled {
    background: #eaecec;
  }

  .Accordionbackgroundmailmenu {
    .panel-heading {
      background-image: linear-gradient(#fff, #fff) !important;
      color: #005380 !important;
    }
  }
  .Accordionbackgroundmailmenu {
    .panel-heading:hover {
      background-image: linear-gradient(#eaecec, #eaecec) !important;
      color: #202020 !important;
    }
    a:hover {
      color: #202020 !important ;
    }
  }
  .Accordionbackgroundmailmenu {
    .panel-heading.active {
      background-image: linear-gradient(
        rgba(41, 160, 245, 0.129),
        rgba(41, 160, 245, 0.129)
      );
      color: #005380 !important;
    }
    a:active {
      color: #005380 !important ;
    }
  }

  // .Lex-Accordion {
  //   .panel-heading {
  //   background-Image: linear-gradient(#005380, #005380) !important;
  //   color:white !important;
  //   border-bottom: 1px solid transparent !important;
  //   margin-bottom: -1px;
  //     }
  //     }
  // .Lex-Accordion {
  //     .panel-heading:hover {
  //     background-Image: linear-gradient(#eaecec, #eaecec)!important;
  //     color:#005380 !important;
  //     border-bottom: 1px solid #005380 !important;
  //     margin-bottom: -1px;}
  //     a:hover {
  //     color:#005380 !important ;
  //     }
  //     }
  // .Lex-Accordion {
  //     .panel-heading.active {
  //     background-Image: linear-gradient(#fff, #fff) !important;
  //     color:#005380 !important;
  //     border-bottom: 1px solid #005380 !important;
  //     margin-bottom: -1px;
  //       }
  //     a:active {
  //     color:#005380 !important ;
  //     }
  //     }

  // .Lex-Accordion .panel-heading {
  //     background-Image: linear-gradient(#f7f7f7, #f7f7f7) !important;
  //     color: #202020 !important; }

  // .Lex-Accordion .panel-heading a {
  //     color:#202020 !important;}

  // .Lex-Accordion .panel-heading:hover {
  //     background-Image: linear-gradient(#005380,#005380) !important;
  //     color: #fff !important; }

  // .Lex-Accordion .panel-heading:hover a {
  //     color: #4CAF50 !important; }

  // .Lex-Accordion .panel-heading.active {
  //     background-Image: linear-gradient(#fff, #fff) !important;
  //     color: #005380!important; }

  // .Lex-Accordion .panel-heading a.active {
  //     color: #005380!important; }

  // .Lex-Accordion.LexAccordionsidebar .panel {
  //     border:0px!important;}

  .Lex-Accordion.caseeditinline {
    .panel-heading {
      background-image: linear-gradient(#eaecec, #eaecec) !important;
      color: #005380 !important ;
      border-bottom: 1px solid white !important;
      margin-bottom: -1px;
    }
  }
  .Lex-Accordion.caseeditinline:hover {
    .panel-heading {
      background-image: linear-gradient(#fff, #fff) !important;
      color: #4caf50 !important ;
      border-bottom: 1px solid #4caf50 !important;
      margin-bottom: -1px;
    }
  }
  .Lex-Accordion.caseeditinline.active {
    .panel-heading {
      background-image: linear-gradient(#fff, #eaecec) !important;
      color: #4caf50 !important ;
      border-bottom: 1px solid #4caf50 !important;
      margin-bottom: -1px;
    }
  }

  .axobg {
    background-color: white;
    color: #005380;
  }

  button.btn.Lex-button {
    background-image: linear-gradient(#fff, #fff);
    color: #005380 !important;
    font-size: 12px;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  button.btn.Lex-button:hover {
    background-image: linear-gradient(#005380, #005380);
    color: white !important;
  }
  button.btn.Lex-button.active {
    background-image: linear-gradient(
      rgba(41, 160, 245, 0.129),
      rgba(41, 160, 245, 0.129)
    );
    color: #005380 !important;
  }
  .accounting .btn {
    background-image: linear-gradient(#fff, #fff);
    color: #005380 !important;
    font-weight: normal !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .accounting .btn:hover {
    background-image: linear-gradient(#005380, #005380);
    color: white !important;
  }
  .accounting .btn.active {
    background-image: linear-gradient(
      rgba(41, 160, 245, 0.129),
      rgba(41, 160, 245, 0.129)
    );
    color: #005380 !important;
  }

  .Lex-CardFooter {
    background-image: linear-gradient(#fff, #fff);
    color: #005380 !important;
  }

  .axogrouplist > div:nth-child(even) {
    background-image: linear-gradient(#fff, #fff);
    color: #005380 !important ;
  }
  .axogrouplist > div:nth-child(odd) {
    background-image: linear-gradient(#f9f9f9, #f9f9f9);
    color: #005380 !important ;
  }
  .axogrouplist > div:hover {
    background-image: linear-gradient(#eaecec, #eaecec);
    color: #005380 !important ;
    border-bottom: 1px solid #005380 !important;
  }
  .axogrouplist div.active {
    background-image: linear-gradient(#fff, #fff);
    color: #005380 !important ;
    border-bottom: 1px solid #005380 !important;
  }

  .TimeButtoncolor {
    background-image: linear-gradient(#fff, #fff);
    color: #005380 !important ;
    border: 1px solid #eaecec !important;
  }
  .Bannecolor {
    background-image: linear-gradient(#fff2e7, #fff2e7) !important;
    color: #005380 !important ;
  }
  .frontPageaxolexdiv.Bannecolor {
    border-bottom: 1px solid #c0a692 !important;
    margin-bottom: -1px;
  }

  .Axolexbg {
    background-image: linear-gradient(#005380, #005380) !important;
    color: #fff !important;
  }
  .main-sidebar .Axolexbg {
    background-image: linear-gradient(#eaecec, #eaecec) !important;
    color: #000 !important;
  }
  .main-sidebar.Axolexbg {
    background-image: linear-gradient(#eaecec, #eaecec) !important;
    color: #000 !important;
  }
  .Axoaccountsbannebg {
    background-image: linear-gradient(#fff, #fff);
    color: #005380;
    border-bottom: 1px solid #005380;
  }

  .axo-nav-lg .btn-group-justified {
    display: inline-block;
  }

  .axo-nav-lg .btn-group-justified > .btn,
  .axo-nav-lg .btn-group-justified > .btn-group {
    display: inline-block;
    width: auto;
  }

  .axo-nav-lg .btn-group-justified > .btn-group .btn {
    width: auto !important;
  }

  .axolextopmenuButton {
    background-image: linear-gradient(#005380, #005380);
    color: #fff !important ;
    border: 0px !important ;
  }
  .axolextopmenuButton:hover {
    background-image: linear-gradient(#fff, #fff);
    color: #005380 !important ;
  }
  .dropdown button.axolextopmenuButton.noboder.Axolextopbtwidth {
    background-image: linear-gradient(#005380, #005380);
    color: #fff;
  }
  .dropdown button.axolextopmenuButton.noboder.Axolextopbtwidth:hover {
    background-image: linear-gradient(#fff, #fff);
    color: #005380;
  }

  //Modal
  .backgroundModalHeaderFooter {
    background-image: linear-gradient(#eaecec, #eaecec) !important;
    color: #202020 !important ;
  }

  // <weathertext>
  .axoweathertext {
    color: #005380 !important;
    font-weight: normal;
  }
  .icon.axoweathertext img {
    border-bottom: 1px solid #202020 !important;
    margin-bottom: -1px;
  }
  .blurred-background.axoweathertextbg {
    background-image: url("../../resources/svg/linktopA.jpg") !important;
    background-repeat: no-repeat !important;
  }
  //</weathertext>

  .Lex-Accordion.IndividualPackageDetal .panel-heading {
    background-image: linear-gradient(#f8f8f8, #eaecec) !important;
    color: #005380 !important;
  }
  .Lex-Accordion.IndividualPackageDetal .panel-heading:hover {
    background-image: linear-gradient(#fff, #fff) !important;
    color: #4caf50 !important;
  }

  // alm banne bg
  .axolextextbanne {
    background-image: linear-gradient(#fde9df, #fde9df) !important;
    color: #005380 !important;
  }

  .editable {
    color: #005380;
  }
  //<dropdown mailmapper>

  .Lex-button-1:hover.btn-link.lex-dropdown {
    color: #c0a692 !important;
    font-weight: normal;
  }
  .btn-link.lex-dropdown {
    color: white !important;
    font-weight: normal;
  }
  .btn-link.lex-dropdown:hover {
    background-image: linear-gradient(#eaecec, #eaecec);
    color: #005380 !important;
    font-weight: normal;
  }

  .slick-next:before,
  .slick-prev:before {
    color: #005380 !important;
    font-size: 40px !important;
  }

  .rbc-today {
    background-color: #fff2e7;
  }

  .panel {
    border: 0px solid transparent;
    border-radius: 4px;
  }

  // netværk
  .border-green {
    border-color: #005380 !important;
  }
  .Axo-button-1 {
    background-image: linear-gradient(#e6e6e6, #e6e6e6);
    color: #4caf50;
  }
  .Axo-button-1:hover {
    background-image: linear-gradient(#4caf50, #4caf50);
    color: #fff !important;
  }
  .Axo-button-1.active {
    background-image: linear-gradient(
      rgba(41, 160, 245, 0.129),
      rgba(41, 160, 245, 0.129)
    );
    color: #005380 !important;
  }

  .dropzone.our-dropzone {
    background: #fff2e7;
    color: #202020 !important;
  }
  .dz-message.our-dz-message {
    color: #202020;
  }

  .axotimeline::after {
    background-color: #005380;
  }
  .timelinecontainer::after {
    background-color: #005380;
    border: 2px solid white;
  }

  .btnSidebarSelect {
    border-right: 1px solid rgba(41, 160, 245, 0.129);
    border-bottom: 1px solid #67abcb;
    background-image: linear-gradient(#f1f3f4, #f1f3f4) !important;
    color: #005380 !important ;
  }
  .btnSidebarSelect:hover {
    border-right: 1px solid rgba(41, 160, 245, 0.129);
    background-image: linear-gradient(#005380, #005380);
    color: #4caf50 !important ;
  }
  .btnSidebarSelect:active {
    border-right: 1px solid #c7e0f4;
    background-image: linear-gradient(#fff, #fff);
    color: #4caf50 !important ;
  }
}
