.language-da {
  font-weight: normal !important;
  color: #202020;

  .hidden-danish {
    display: none !important;
  }

  .visible-danish {
    display: block !important;
  }
}
